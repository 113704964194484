// @flow
import React from 'react';
import { Router, Switch, Route } from "react-router-dom";
import { useAuth } from './providers/CustomAuthProvider';
import { SnackbarProvider } from 'notistack';
import history from "./utils/history";
//import './assets/scss/Saas.scss';
import './assets/scss/Creative.scss';
import PrivateRoute from './routes/PrivateRoute';
import JsonSnackbar from 'components/JsonSnackbar';
import Profile from './components/Profile';
import Layout from './layouts/Vertical';
import LogoutPage from './pages/account/Logout';
import licensesRawData from './pages/license/licensesRawData';
import offersRawData from './pages/offers/offersRawData';
import DashboardPage from './pages/dashboard/dashboard';
import Loader from './components/Loader';
import LicensedCompanies from './pages/license/licenseFlow';
import CompaniesData from './pages/companies/companiesData';
import CompaniesDetailsData from './pages/companies/companiesDetailsData';
import CompaniesDetailsDataNew from './pages/companies/companiesDetailsDataNew';
import CompaniesDataNew from './pages/companies/companiesDataNew';

import UsersList from './pages/users/UserList';
import UserOverview from './pages/users/UserOverview'
import ManageNotifications from './pages/notifications/manage';
import {NotificationHub} from './utils/notificationReceiver';
import {UserProfile} from './pages/users/profile';
import Settings from './pages/settings';
const App = () => {

    const { isLoading, error } = useAuth();

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <Loader />;
    }

    return (
        <SnackbarProvider
            Components={{
                json: JsonSnackbar
            }}
            maxSnack={6}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
                    <Router history={history}>
                        <Switch>
                            <PrivateRoute layout={Layout} path="/" exact component={DashboardPage} />
                            <PrivateRoute layout={Layout} path="/licenses/browse" exact component={LicensedCompanies} />
                            <PrivateRoute layout={Layout} path="/licenses/rawData" exact component={licensesRawData} />
                            <PrivateRoute layout={Layout} path="/offers/rawData" exact component={offersRawData} />
                            {/* Companies */}
                            <PrivateRoute layout={Layout} path="/companies" exact component={CompaniesData} />
                            <PrivateRoute layout={Layout} path="/companies/companiesDataNew" exact component={CompaniesDataNew} />
                            <PrivateRoute layout={Layout} path="/companies/companiesDetailsData/:companyId/:companyName" exact component={CompaniesDetailsData} />
                            <PrivateRoute layout={Layout} path="/companies/:companyId" exact component={CompaniesDetailsDataNew} />
                            {/* Notification */}
                            <PrivateRoute layout={Layout} path="/notifications" exact component={ManageNotifications} />
                            <PrivateRoute layout={Layout} path="/Profile" exact component={UserProfile}/>

                            {/* Settings */}
                            
                            <PrivateRoute layout={Layout} path="/settings/users/:userId/overview" AdminAccessOnly exact component={UserOverview}/>
                            <PrivateRoute layout={Layout} path="/settings" component={Settings}/>
                            
                            <Route path="/account/logout" component={LogoutPage} />
                        </Switch>
                    </Router>
        </SnackbarProvider>
        
    );
};

export default App;
