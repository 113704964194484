import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Control, FieldErrors, UseFormWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import stateCodes from 'constants/stateCodes';
import timezones from 'constants/timezones';
import clientUserRoles from 'constants/clientUserRoles';
import FormInput from 'components/FormInput/index';
// import { getCustomerFormData } from 'features/customer/queries/get-form-data.query';
import ErrorMessage from 'components/ErrorElement';
// import { getCustomersByKey } from 'features/customer/queries/get-customers-by-key.query';
// import { CategoryEnum } from 'consts/category.enum';
// import { getAddressDropdownData } from 'features/address/queries/get-address-dropdown.query';

const stateCodesOptions = stateCodes.map(code => {
    return {id: code, name: code};
});

const timezoneOptions = timezones.map(zone => {
    return {id: zone, name: zone};
});

const clientUserRolesOptions = clientUserRoles.map(role => {
    return {id: role, name: role};
});

const UserFormInputs = ({
    register,
    errors,
    control,
    isUpdateMode = false,
    watch
}) => {
    // const { data, isLoading, error } = getCustomerFormData();

    // const [parentCustomersKey, setParentCustomersKey] = useState<string>('');

    // if (error || errorCustomers || statesError) {
    //     return <ErrorMessage message="Something went wrong on our end" />;
    // }


    return (
        <>
            {
                isUpdateMode && 
                (<>
                    <FormInput
                        type="hidden"
                        name="id"
                        labelClassName="required"
                        containerClass={'mb-3'}
                        register={register}
                        key="name"
                        errors={errors}
                    />
                    <FormInput
                    type="hidden"
                    name="secureId"
                    labelClassName="required"
                    containerClass={'mb-3'}
                    register={register}
                    key="name"
                    errors={errors}
                />
                </>)
            }
            <Row>
                <Col lg={6}>
                    <FormInput
                        label="Email Address"
                        type="text"
                        name="emailAddress"
                        labelClassName="required"
                        containerClass={'mb-3'}
                        register={register}
                        key="name"
                        errors={errors}
                        validation={{
                            required: {
                                value: true,
                                message: 'EmailAddress is required.'
                            },
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                                message: 'Not a valid email address.',
                            },
                            maxLength: {
                                value: 50,  // Max length of 50 characters
                                message: 'emailAddress cannot exceed 50 characters'
                            },
                        }}
                    />
                </Col>
                <Col lg={4}>
                    <FormInput
                        type="select"
                        label="User Role"
                        labelClassName={'required'}
                        name="role"
                        options={clientUserRolesOptions}
                        control={control}
                        errors={errors}
                        labelKey="name"
                        containerClass={'mb-3'}
                        validation={{
                            required: "Please select a Role"
                        }}
                    />
                </Col>
            </Row>
            <h5>General Details</h5>
            <hr />
            <Row>
                <Col lg={6}>
                    <FormInput
                        label="First Name"
                        type="text"
                        name="firstName"
                        labelClassName="required"
                        containerClass={'mb-3'}
                        register={register}
                        key="name"
                        errors={errors}
                        validation={{
                            required: {
                                value: true,
                                message: 'First Name is required.'
                            }
                        }}
                    />
                </Col>
                <Col lg={6}>
                    <FormInput
                            label="Last Name"
                            type="text"
                            name="lastName"
                            labelClassName="required"
                            containerClass={'mb-3'}
                            register={register}
                            key="name"
                            errors={errors}
                            validation={{
                                required: {
                                    value: true,
                                    message: 'Last Name is required.'
                                }
                            }}
                        />
                </Col> 
            </Row>         
            <Row>
                <Col lg={6}>
                    <FormInput
                        label="Address Line 1"
                        type="text"
                        name="address1"
                        labelClassName={'required'}
                        containerClass={'mb-3'}
                        register={register}
                        errors={errors}
                    />
                </Col>
                <Col lg={6}>
                    <FormInput
                        label="Address Line 2"
                        type="text"
                        name="address2"
                        containerClass={'mb-3'}
                        register={register}
                        errors={errors}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <FormInput
                        label="City"
                        type="text"
                        labelClassName={''}
                        name="city"
                        containerClass={'mb-3'}
                        register={register}
                        key="city"
                        errors={errors}
                        validation={{
                            required: {
                                value: false,
                                message: 'This field is required.'
                            }
                        }}
                    />
                </Col>
                <Col lg={4}>
                    <FormInput
                        type="select"
                        label="State"
                        labelClassName={'required'}
                        name="stateCode"
                        options={stateCodesOptions}
                        control={control}
                        errors={errors}
                        labelKey="name"
                        containerClass={'mb-3'}
                    />
                </Col>
                <Col lg={4}>
                    <FormInput
                        type="zipCode"
                        label="Zip"
                        labelClassName={''}
                        name="zipCode"
                        errors={errors}
                        containerClass={'mb-3'}
                        control={control}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FormInput
                        type="select"
                        label="Timezone"
                        labelClassName={'required'}
                        name="timezone"
                        options={timezoneOptions}
                        control={control}
                        errors={errors}
                        labelKey="name"
                        containerClass={'mb-3'}
                    />
                </Col>
                <Col lg={6}>
                    <FormInput
                        type="phone"
                        label="Phone Number"
                        name="phoneNumber"
                        control={control}
                        errors={errors}
                        containerClass="mb-3"
                        validation={{
                            pattern: {
                                value: /^\(\d{3}\) \d{3}-\d{4}$/,
                                message: 'Please enter a valid phone number'
                            }
                        }}
                    />
                </Col>
            </Row>

        </>
    );
};

export default UserFormInputs;
