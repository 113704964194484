import React, { useEffect, useState } from "react";
import UserOverviewHeader from '../UserOverview/Header';
import { DEV_URL, rget, rpost } from "utils/requests";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import Div from "components/Div";
import TextLine from "components/TextLine";
import { useToggle } from "hooks";
import UserFormModal from "../UserFormModal";
import { useAuth } from "providers/CustomAuthProvider";
export interface UserProfileProps{

}

export const UserProfile : React.FC<UserProfileProps> = ({})=>{
    const defaultDetails = {
        emailAddress:'',
        firstName:'',
        lastName:'',
        phoneNumber:'',
        timeZone:'',
        address1:'',
        address2:'',
        city:'',
        stateCode:'',
        zipCode:''
    }
    const [loadingUserData, setLoadingUserData] = useState(false);
    const { isOpen, show, hide } = useToggle();
    const [userData, setUserData] = useState(defaultDetails);
    let { user, clientId } = useAuth();
    const fetchdata = async () => {
        setLoadingUserData(true);
        const bodyParams = {
            userId: user?.sub
        };
        const userDataResponse = await rpost(
            `${DEV_URL}/api/client/${clientId}/userInfo`,
            bodyParams
        );  
        const userData = userDataResponse?.data??defaultDetails;
        setUserData(userData);
    };
    useEffect(() => {
        fetchdata();
      }, []);




    return (<>
    <UserOverviewHeader isBack={false} user={userData}/>
    <Card className="mt-2">
                <Card.Body>
                    <Div className="d-flex align-items-center justify-content-between">
                        <Card.Title className="mb-4">
                            <h3>User Details</h3>
                        </Card.Title>
                        <Col sm={3}>
                            <Div className="text-sm-end">
                                <Button
                                    variant="success"
                                    className="mb-2 me-1"
                                    size="sm"
                                    onClick={show}
                                >
                                    Edit
                                </Button>
                            </Div>
                        </Col>
                    </Div>
                    <Row>
                        <Col xs={4}>
                            <TextLine
                                primary="Email Address:"
                                secondary={userData?.emailAddress}
                            />   
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="First Name:"
                                secondary={userData?.firstName}
                            />
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="Last Name:"
                                secondary={userData?.lastName}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <TextLine
                                primary="Phone:"
                                secondary={userData?.phoneNumber}
                            />   
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="Timezone:"
                                secondary={userData?.timeZone}
                            />   
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="Address Line 1:"
                                secondary={userData?.address1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <TextLine
                                primary="Address Line 2:"
                                secondary={userData?.address2}
                            />
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="City:"
                                secondary={userData?.city}
                            />
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="State:"
                                secondary={userData?.stateCode}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <TextLine
                                primary="Zip:"
                                secondary={userData?.zipCode}
                            />
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col xs={3}>
                            <EditableTextLine
                                entity={customer}
                                primary="Website"
                                field="websiteUrl"
                                type="text"
                                mutate={mutate}
                                isLoading={isLoading}
                                successMessage={customer_messages.EDIT_SUCCESS}
                                errorMessage={customer_messages.EDIT_ERROR}
                            />
                        </Col>
                        <Col xs={3}>
                            <EditableTextLine
                                entity={customer}
                                primary="Parent Customer"
                                field="parentCustomer"
                                secondary={customer?.parentCustomer?.name || ''}
                                type="asyncTypeahead"
                                mutate={mutate}
                                isLoading={isLoading}
                                options={customers}
                                isLoadingData={isLoadingCustomers}
                                onSearch={setParentCustomersKey}
                                enableEditing={!errorCustomers}
                                successMessage={customer_messages.EDIT_SUCCESS}
                                errorMessage={customer_messages.EDIT_ERROR}
                            />
                        </Col>
                        <Col xs={3}>
                            <EditableTextLine
                                entity={customer}
                                primary="DUNS"
                                field="dUNS"
                                type="text"
                                mutate={mutate}
                                isLoading={isLoading}
                                successMessage={customer_messages.EDIT_SUCCESS}
                                errorMessage={customer_messages.EDIT_ERROR}
                            />
                        </Col>
                        <Col xs={3}>
                            <TextLine
                                primary="CRM Customer ID:"
                                secondary={
                                    customer.crmid ? customer.crmid : '-'
                                }
                            />
                        </Col>
                    </Row> */}
                </Card.Body>
            </Card>
            {isOpen && (
                <UserFormModal afterSubmitCallback={fetchdata} key={'userUpdateForm'} isOpen={isOpen} hide={hide} isUpdateMode={true} user={userData} />
            )}
    </>)
}

