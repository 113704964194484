import { Card, Row, Col } from "react-bootstrap";
import Div from "components/Div";
import { trimInvalidDate } from './../../../../helpers/api/utils';
import TextLine from "components/TextLine";
import Text from 'components/Text';
import { ICaseHistory } from "pages/companies/types";
import { useEffect, useState } from "react";
import Spinner from "../../../../components/Spinner";

type CaseHistoryDetailsProps = {
  caseHistoryData: ICaseHistory[];
  loadingCaseHistoryData: boolean;
};

const CaseHistoryDetails = ({
  caseHistoryData,
  loadingCaseHistoryData,
}: CaseHistoryDetailsProps) => {
  return (
    <>
      <Card className="bg-light">
        <Card.Body className="bg-light">
          {loadingCaseHistoryData && (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner />
            </div>
          )}
          {caseHistoryData &&
            caseHistoryData.length > 0 ?
            caseHistoryData.map((history) => (
                <Div className="bg-light font-sans-serif mb-4">
                    <Row className="mb-1">
                        <Col xl={4}>
                            <Row>
                                <TextLine
                                    primary="Case Number:"
                                    labelClass="text-dark"
                                    secondary={history.caseNumber ?? ""}
                                    url={history.pucUrl}
                                    isExternalUrl={true}
                                />
                            </Row>
                        </Col>
                        <Col xl={4}>
                            <Row>
                                <TextLine
                                    primary={"Case Title:"}
                                    labelClass="text-dark"
                                    secondary={history.caseTitle ?? ""}
                                />
                            </Row>
                        </Col>
                        <Col></Col>
                    </Row>
                <Row className="mb-1">
                  <Col xl={4}>
                      <Row>
                        <TextLine
                                    primary={"Case Open Date:"}
                                    labelClass="text-dark"
                                    secondary={trimInvalidDate(history.caseOpenDate ? history.caseOpenDate.split("T")[0]:"")}
                        />
                      </Row>
                  </Col>
                  <Col xl={4}>
                      <>
                        <Row>
                          <TextLine
                                        primary={"Case Close Date:"}
                                        labelClass="text-dark"
                                        secondary={trimInvalidDate(history.caseCloseDate ? history.caseCloseDate.split("T")[0] : "")}
                          />
                        </Row>
                      </>
                  </Col>
                  <Col></Col>
                    </Row>
                    <Row className="mb-1">
                        <Col xl={4}>
                            <Row>
                                <TextLine
                                    primary="Status:"
                                    labelClass="text-dark"
                                    secondary={history.caseStatus ?? ""}
                                />
                            </Row>
                        </Col>
                        <Col xl={4}>
                            <Row>
                                <TextLine
                                    primary={"Case Desc:"}
                                    labelClass="text-dark"
                                    secondary={history.caseType ?? ""}
                                />
                            </Row>
                        </Col>
                        <Col></Col>
                    </Row>
                <Row className="mb-1">
                  <Div>
                            <TextLine primary="Note: "
                                labelClass="text-dark" secondary={history?.caseNote} />
                  </Div>
                </Row>
                <hr />
                </Div>
            )) : <Text className="text-center" variant="subtitle">{"No cases recorded for this license."}</Text>}
        </Card.Body>
      </Card>
    </>
  );
};

export default CaseHistoryDetails;
