import Card from 'react-bootstrap/Card';
import { CustomContentProps, SnackbarContent } from 'notistack';
import { forwardRef } from 'react';

const JsonSnackbar = forwardRef<HTMLDivElement, CustomContentProps>(
    ({ ...props }, ref) => {
        return (
            <SnackbarContent ref={ref}>
                <Card
                    bg="success"
                    className="text-white"
                    style={{ maxHeight: 700, overflow: 'auto' }}
                >
                    <Card.Body>
                        <pre>{props.message}</pre>
                    </Card.Body>
                </Card>
            </SnackbarContent>
        );
    }
);

JsonSnackbar.displayName = 'JsonSnackbar';

export default JsonSnackbar;
