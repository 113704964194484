import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from '@auth0/auth0-react';

const CustomAuthContext = createContext();

function CustomAuthProvider({children}) {

    const DEFAULT_CLIENT_ID = "BEACBE71-B7B3-EF11-8474-7C1E5210BEC2";
    const { user, isAuthenticated, isLoading, error, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();

    // states
    const [isAdmin, setIsAdmin] = useState(null);
    const [isSuperAdmin, setIsSuperAdmin] = useState(null);
    const [hasInsightAccess, setHasInsightAccess] = useState(null);
    const [accessToken, setAccessToken] = useState(null);
    const [clientId, setClientId] = useState(null);

    useEffect(() => {
        const userAppMeta = user && user['https://enerex.com/app_metadata'] || {};
        const allowedAccess = userAppMeta?.allowedAccess || [];
        const Roles = userAppMeta?.Roles || [];
        const clientIdValue = userAppMeta?.ClientId;

        const isSuperAdminValue = allowedAccess?.includes('root:INS');
        const hasInsightAccessValue = isSuperAdminValue || (clientIdValue && allowedAccess?.includes('access:INS'));
        const isAdminValue = isSuperAdminValue || Roles?.includes('Admin');

        // TODO: to plan on how to handle superadmin scenario instead of default client Id
        setClientId(isSuperAdminValue ? DEFAULT_CLIENT_ID : clientIdValue);
        setIsSuperAdmin(isSuperAdminValue);
        setIsAdmin(isAdminValue);
        setHasInsightAccess(hasInsightAccessValue);
    }, [user]);

    useEffect(() => {
        getAccessToken();
    }, []);

    // const logout = () => {
    //     localStorage.removeItem('token');
    //     // Auth0logout({});
    // };

    async function getAccessToken() {
        try {
            const token = await getAccessTokenSilently();
            setAccessToken(token);
            localStorage.setItem("token", token);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <CustomAuthContext.Provider value={{ logout, loginWithRedirect, isLoading, error, user, clientId, isAuthenticated, isAdmin, hasInsightAccess, isSuperAdmin, accessToken}}>
            {children}
        </CustomAuthContext.Provider>
    );
}

const useAuth = () => useContext(CustomAuthContext);

export { useAuth };

export default CustomAuthProvider;

CustomAuthProvider.propTypes = {
    getToken: PropTypes.func
};