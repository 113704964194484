import classNames from 'classnames';
import { Control, Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

type PhoneInputProps = {
    name: string;
    validation?: Record<string, any>;
    control?: Control<any>;
    className?: string;
    disabled?: boolean;
    isInvalid?: boolean;
};

const PhoneInput = ({
    name,
    validation,
    control,
    className,
    disabled,
    isInvalid
}: PhoneInputProps) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={validation}
            render={({ field: { onChange, value } }) => (
                <MaskedInput
                    mask={[
                        '(',
                        /[1-9]/,
                        /\d/,
                        /\d/,
                        ')',
                        ' ',
                        /\d/,
                        /\d/,
                        /\d/,
                        '-',
                        /\d/,
                        /\d/,
                        /\d/,
                        /\d/
                    ]}
                    placeholder="(999) 999-9999"
                    className={classNames('form-control', className, {
                        'is-invalid': isInvalid
                    })}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    // isInvalid={errors && errors[name] ? true : false}
                />
            )}
        />
    );
};

export default PhoneInput;
