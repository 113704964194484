import DetailsHeader from 'components/DetailsHeader';
import Div from 'components/Div';
import TextLine from 'components/TextLine';
import Text from 'components/Text';
import { Badge } from 'react-bootstrap';
import { StatusCodes } from '../types/StatusCodes';
import UserIcon from 'components/icons/User';

const UserOverviewHeader = ({ user,isBack=true }) => {
    const dateFormat = "MM/dd/yy";
    const logo_error = (e) => {
        e.target.style.display = 'none';
        e.target.nextElementSibling.style.display = 'block';
    }

    let statusBadge = <>Not Available</>;
    if (user?.statusCode == StatusCodes.Active)
        statusBadge = <Badge bg="success">Active</Badge>;
    if (user?.statusCode == StatusCodes.Blocked)
        statusBadge = <Badge bg="danger">Blocked</Badge>;
    
    const userCreationDate = new Date(user?.createdDate);
    return (
        <DetailsHeader
            isBack = {isBack}
            /* icon={<CustomerIcon style={{ height: '50px' }} />} */
            icon={<UserIcon className='ms-2' fontSize={64} />}
            title={<DetailsHeader.Title title={user?.lastName + ", " + user?.firstName} />}
            pageTitle={user?.emailAddress}
            subtitle={user?.emailAddress}
            subtitleLink={`mailto:${user?.emailAddress}`}
            detailFields={
                <Text variant="plain">{user?.companyType}</Text>
            }
            goBackLinkText={'Users'}
            rightBox={
                <Div className="d-flex justify-content-end">
                    <Div className="d-flex flex-column">
                        <TextLine
                            primary="User Since:"
                            secondary={!isNaN(userCreationDate) ?  userCreationDate.toLocaleDateString('en-US') : '--'}
                        />
                        <TextLine
                            primary="Role:"
                            secondary={user?.role || '-'}
                        />
                        <span>{statusBadge}</span>
                    </Div>
                </Div>
            }
        />
    );
};

export default UserOverviewHeader;
