import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import classNames from 'classnames';
import { FieldErrors } from 'react-hook-form';

import { useToggle } from 'hooks';

type PasswordInputProps = {
    name: string;
    placeholder?: string;
    refCallback?: any;
    errors: FieldErrors;
    validation?: Record<string, any>;
    register?: any;
    className?: string;
};

/* Password Input */
const PasswordInput = ({
    name,
    placeholder,
    refCallback,
    errors,
    register,
    validation,
    className
}: PasswordInputProps) => {
    const { isOpen: showPassword, toggle: togglePassword } = useToggle();

    return (
        <InputGroup className="mb-0">
            <Form.Control
                type={showPassword ? 'text' : 'password'}
                placeholder={placeholder}
                name={name}
                id={name}
                as="input"
                ref={(r: HTMLInputElement) => {
                    if (refCallback) {
                        refCallback(r);
                    }
                }}
                className={className}
                isInvalid={errors && errors[name] ? true : false}
                {...(register ? register(name, { ...validation }) : {})}
                autoComplete={name}
            />
            <div
                className={classNames(
                    'input-group-text',
                    'input-group-password',
                    {
                        'show-password': showPassword
                    }
                )}
                data-password={showPassword ? 'true' : 'false'}
            >
                <span className="password-eye" onClick={togglePassword}></span>
            </div>
        </InputGroup>
    );
};

export default PasswordInput;
