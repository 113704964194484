import { format } from 'date-fns';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useHistory, useLocation } from "react-router-dom";
import useShowSnackbar from 'hooks/useShowSnackbar';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import List from 'components/List';
import ListItem from 'components/List/ListItem';
import LoadingButton from 'components/LoadingButton';

// import type { IChannelPartner } from 'features/channel-partner/types';
// import type { ICustomer } from 'features/customer/types';
// import { useSettings } from 'features/settings/settings.context';
// import { Endpoints } from 'routes/endpoints';
// import type { IIndustry, ISalesperson, IUSState } from 'types';
import { IUserData } from '../types';
import { DEV_URL, rpost, rput } from "../../../utils/requests";
import UserFormInputs from './UserFormInputs';
import { useAuth } from 'providers/CustomAuthProvider';

type Inputs = {
    id?: string;
    emailAddress: string;
    initialPassword: string;
    firstName: string;
    lastName: string;
    status?: string;
    secureId?: string;
    address1: string,
    address2: string,
    city: string,
    timezone: any,
    zipCode: string,
    stateCode: any,
    phoneNumber: string,
    role: any
};


// type UserFormProps = {
//     isOpen: boolean;
//     hide: () => void;
//     user?: any;
//     afterSubmitCallback?: (
//         response: Pick<any, 'id' | 'name' | 'externalId'>
//     ) => void;
// };

const UserFormModal = ({
    isOpen,
    hide,
    user = null,
    isUpdateMode = false,
    afterSubmitCallback = () => null
}) => {
    // const { dateFormat } = useSettings();

    const showSnackbar = useShowSnackbar();
    const history = useHistory();
    const location = useLocation();
    const [isReponsePending, setIsResponsePending] = useState(false);
    const { clientId } = useAuth();
    const defaultValues = {
        id: user?.id || '',
        secureId: user?.secureId || '',
        emailAddress: user?.emailAddress || '',
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        address1: user?.address1 || '',
        address2: user?.address2 || '',
        city: user?.city || '',
        stateCode: user?.stateCode ? {id: user?.stateCode, name: user?.stateCode} : '',
        zipCode: user?.zipCode || '',
        timezone: user?.timeZone ? {id: user?.timeZone, name: user?.timeZone} : '',
        phoneNumber: user?.phoneNumber || '',
        role: user?.role ? {id: user?.role, name: user?.role} : ''
    }
    
    const {
        handleSubmit,
        register,
        control,
        formState: { errors },
        reset,
        watch,
        setValue
    } = useForm({
        defaultValues: isUpdateMode ? defaultValues : {}
    });


    const onSubmit:SubmitHandler<Inputs> = async (data) => {
        // if(data != null) console.log(data, formstate);
        const postParams = {
            id: data?.id,
            secureId: data?.secureId,
            emailAddress: data?.emailAddress,
            password: data?.initialPassword,
            firstName: data?.firstName,
            lastName: data?.lastName,
            address1: data?.address1,
            address2: data?.address2,
            city: data?.city,
            stateCode: data?.stateCode?.id,
            zipCode: data?.zipCode,
            timezone: data?.timezone?.id,
            phoneNumber: data?.phoneNumber,
            role:data?.role?.id
        };

        setIsResponsePending(true);
        // Create User Modal Request
        if(!isUpdateMode)
        {
            const response = await rpost(
                `${DEV_URL}/api/client/${clientId}/user`,
                postParams
            );
            //TODO - make it uniform
            showSnackbar(
                response?.data || 'something went wrong!',
                response?.data && (response?.status == 200) ? 'success' : 'error'
            )
            if (response?.status == 200) {
                reset(defaultValues);
                hide();
            }
        }
        // Update User Modal Request
        else
        {
            const response = await rput(
                `${DEV_URL}/api/client/${clientId}/user`,
                postParams
            );
            showSnackbar(
                response?.data || 'something went wrong!',
                response?.data && (response?.status == 200) ? 'success' : 'error'
            )
            reset();
            hide();
        }
        
        setIsResponsePending(false);
        afterSubmitCallback();
    };

    return (
        <Form>
            <Modal
                show={isOpen}
                onHide={() => {
                    reset(defaultValues);
                    hide();
                }}
                size={'lg'}
                scrollable={true}
                backdrop="static"
                className="modal-level-1"
                backdropClassName="modal-backdrop-level-1"
            >
                <Modal.Header onHide={hide} closeButton>
                    <h4 className="modal-title">
                        {isUpdateMode? "Update User" : "Create User"}
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <UserFormInputs
                        control={control}
                        errors={errors}
                        register={register}
                        watch={watch}
                        isUpdateMode={isUpdateMode}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {/*  <Button
                        variant="light"
                        onClick={hide}
                        disabled={
                            isLoading || isLoadingAddress || isLoadingEdit
                        }
                    >
                        Close
                    </Button>{' '} */}
                    <LoadingButton
                        isLoading={isReponsePending}
                        type="button"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {isUpdateMode? "Update" : "Create"}
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </Form>
    );
};

export default UserFormModal;
