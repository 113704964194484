import Div from "components/Div"
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"

interface ISubscribeModalProp{
    companyId:string
    show?:boolean;
    setShow?:Dispatch<SetStateAction<boolean>>
    value:ISubscribeOptions,
    onSubmit?:Function
}
export interface ISubscribeOptions{
    web:boolean,
    email:boolean,
    sms:boolean,
    push:boolean,
}

export const SubscribeModal:React.FC<ISubscribeModalProp> = ({companyId,show,setShow,value,onSubmit})=>{
const [changeValues,setChangeValues] = useState<ISubscribeOptions>({...value});
const handleClose = () => setShow(false);
const onChangeValues=(prop)=>{
    value = {...changeValues};
    if(prop==='all'){
        if(value.web===true&&value.email===true&&value.sms===true&&value.push===true){
            value = {
                web:false,
                email:false,
                sms:false,
                push:false,
            }
        }else{
            value = {
                web:true,
                email:true,
                sms:true,
                push:true,
            }
        }
    }

    value[prop]=!value[prop];
    setChangeValues(value);
}
return (
    <Modal dialogClassName="NotificationConfig" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notifications Alert</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Row>
                <Col md="12" className="mb-2"><Form.Check type="checkbox" checked={(changeValues.web===true&&changeValues.email===true&&changeValues.sms===true&&changeValues.push===true)} onChange={()=>{onChangeValues('all'); }} label="Select All"></Form.Check></Col>
                <Col md="12" className="mb-2"><Form.Check type="checkbox" checked={changeValues.web} onChange={()=>{onChangeValues('web'); }} label="Web Notification"></Form.Check></Col>
                <Col md="12" className="mb-2"><Form.Check type="checkbox" checked={changeValues.email} onChange={()=>{onChangeValues('email');}} label="Email"></Form.Check></Col>
                <Col md="12" className="mb-2"><Form.Check type="checkbox" checked={changeValues.sms} onChange={()=>{onChangeValues('sms');}} label="SMS"></Form.Check></Col>
                <Col md="12" className="mb-2"><Form.Check type="checkbox" checked={changeValues.push} onChange={()=>{onChangeValues('push');}} label="Push Notification"></Form.Check></Col>
                </Row>
            </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>{handleClose();onSubmit!({...changeValues});}}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
)
}