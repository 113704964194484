import classNames from 'classnames';
import { Control, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

type ZipInputProps = {
    name: string;
    validation?: Record<string, any>;
    control?: Control<any>;
    className?: string;
    disabled?: boolean;
    isInvalid?: boolean;
};

const ZipInput = ({
    name,
    validation,
    control,
    className,
    disabled,
    isInvalid
}: ZipInputProps) => {
    const beforeMaskedValueChange = (newState, oldState, userInput) => {
        let { value } = newState;
        let selection = newState.selection;
        let cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-') {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = {
                    start: cursorPosition,
                    end: cursorPosition
                };
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    };
    return (
        <Controller
            control={control}
            name={name}
            rules={validation}
            render={({ field: { onChange, value } }) => (
                <InputMask
                    // {...(register
                    //     ? register(name, {
                    //           ...validation
                    //       })
                    //     : {})}
                    onChange={onChange}
                    value={value}
                    mask="99999-9999"
                    maskChar={null}
                    placeholder="XXXXX-XXXX"
                    className={classNames('form-control', className, {
                        'is-invalid': isInvalid
                    })}
                    disabled={disabled}
                    beforeMaskedValueChange={beforeMaskedValueChange}
                />
            )}
        />
    );
};

export default ZipInput;
