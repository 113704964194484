import CustomerIcon from "components/icons/Customer";
import { useImperativeHandle, useState,forwardRef } from "react"
import { Alert, Button, Col, Modal, Row } from "react-bootstrap";
import { ICompanyData } from "./types";
import useShowSnackbar from "hooks/useShowSnackbar"
import {DEV_URL,rpost} from 'utils/requests';
import Text from "components/Text" 
import ArrowRightIcon from "components/icons/ArrowRight";
import SearchableSelect,{Option} from "components/SearchableSelect";
import Spinner from "components/Spinner";
import InfoIcon from "components/icons/InfoIcon";
import ConfirmButton from "components/ConfirmButton";

export interface MergeCompanyProps {
    onShowMergeCompany: (companyData:any)=> void
}

const  MergeCompanyComponent = forwardRef<MergeCompanyProps,{}>((porps,ref)=>{
    const snackbar = useShowSnackbar();
    
    const [show,setShow] = useState(false);
    const [company,setCompany] = useState<ICompanyData>();
    const [progress,setProgress] = useState(false);
    const [targetCompanyDetails,setTargetCompanyDetails] =  useState<Option | null>(null);
    const [error,setError] = useState('');
    const logo_error = (e) => {
        e.target.style.display = 'none';
        e.target.nextElementSibling.style.display = 'block';
    }
    const list = [{
        value:{name:'abcd'},
        label:'abcd'
    },{
        value:{name:'abcdefg'},
        label:'abcdefg'
    }];
    const onShowMergeCompany = (companyData)=>{
        setTargetCompanyDetails(null);
        setError('');
        setCompany(companyData);
        setShow(true);
    }
    useImperativeHandle(ref, () => ({
        onShowMergeCompany
    }));
    
    const loadOptions = async (inputValue: string) => {
        if (!inputValue) {
            return []; // Return empty array if no input is provided
          }
          try {
            // Example: using JSONPlaceholder's users endpoint
            const response = await rpost(`${DEV_URL}/api/company/SearchCompany`, {Search:inputValue,Limit:50});
            if(response.data!==null){
                const options = response.data.data.map((company: any) => ({
                  value: company,
                  label: company.legalName,
                }));
            
                return options;
            }else{
                return [];
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            return [];
          }
    }
    const changeValueHandler = (selected: Option | null)=>{
        setTargetCompanyDetails(selected)
    }
    const onMerge = async ()=>{
        setError('');
        if(targetCompanyDetails?.value){
            try {
                if(targetCompanyDetails?.value.id===company.id){
                    setError('can not merge with self');
                    return;
                }
                setProgress(true);
                const response = await rpost(`${DEV_URL}/api/company/MergeCompany`, 
                    {
                        TargetCompanyId:targetCompanyDetails?.value.id,
                        SourceCompanyId:company.id
                    });
                if(response.data){
                    snackbar('Company Merged successfully','success');
                    setShow(false);
                }else{
                    setError('request can not accept this time');
                }
                setProgress(false);
            } catch (error) {
                setError('request can not accept this time');
                setProgress(false);
            }
        }
    }
    return (<>
    
    <Modal size="lg" centered show={show} onHide={()=>{setShow(false)}}>
        <Modal.Header closeButton>
          <Modal.Title>Merge Company</Modal.Title>
        </Modal.Header>
        <Modal.Body className=" p-3">
            {
                error!==''?<Alert variant="danger" >
                <b>Error</b> : {error}
              </Alert>:''
            }
        
            <Row className="position-relative">
                <div className="position-absolute" style={{left:'50%',top:'50%',width:'45px',transform:'translate(-50%,-50%)'}}>
                    <ArrowRightIcon fontSize={20}/>
                </div>
                <Col>
                    <div className="text-center">
                        {(targetCompanyDetails?.value.emailDomain === undefined || targetCompanyDetails?.value.emailDomain === null || targetCompanyDetails?.value.emailDomain === '') ?
                        <CustomerIcon className="mx-auto my-4" fontSize={64} /> : <span><img className='copnay-icons' style={{width:'135px',height:'auto'}} onError={logo_error} src={'https://logo.clearbit.com/' + (targetCompanyDetails?.value.emailDomain??'')} /><CustomerIcon fontSize={64}  className="mx-auto" style={{ display: 'none' }} /></span>}
                    </div>
                    <div className="text-center mt-3">
                        <div className="h5">
                        {targetCompanyDetails?.value.legalName}
                        </div>
                        <Text variant="link" url={'https://'+targetCompanyDetails?.value.emailDomain}>{targetCompanyDetails?.value.emailDomain}</Text>
                    </div>
                    <div className="text-center mt-3">
                        <SearchableSelect loadOptions={loadOptions} value={targetCompanyDetails} onChange={changeValueHandler}></SearchableSelect>
                    </div>
                </Col>
                <Col>
                    <div className="text-center">
                    {(company?.emailDomain === undefined || company?.emailDomain === null || company?.emailDomain === '') ?
                        <CustomerIcon className="mx-auto my-4" fontSize={64} /> : <span><img className='copnay-icons' style={{width:'135px',height:'auto'}} onError={logo_error} src={'https://logo.clearbit.com/' + (company?.emailDomain??'')} /><CustomerIcon fontSize={64}  className="mx-auto" style={{ display: 'none' }} /></span>}
                    </div>
                    <div className="text-center mt-3">
                        <div className="h5">
                        {company?.legalName}
                        </div>
                        <Text variant="link" url={'https://'+company?.emailDomain}>{company?.emailDomain}</Text>
                    </div>
                </Col>
            </Row>
            <div className="mt-4">
            <Alert variant="warning" >
            <InfoIcon/>&nbsp;The "Merge Company" option integrates key company components, including licenses, addresses, contacts, and domains, into one cohesive entity. This allows businesses to optimize their structure, reduce administrative overhead, and improve operational efficiency. By consolidating these elements, the merged company can present a unified brand and streamline its processes for both internal and external stakeholders.
            </Alert>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setShow(false)}}>
            Close
          </Button>
          <ConfirmButton label="Merge" showCancelButton={true} onConfirm={onMerge} confirmLabel="Yes" cancelLabel="No" message={'Are you sure you want to merge '+targetCompanyDetails?.value.legalName+' into '+company?.legalName+'?'} disabled={targetCompanyDetails===null}>

          </ConfirmButton>
        </Modal.Footer>
      </Modal>
    </>);
});

export default MergeCompanyComponent;