import { faFlag } from '@fortawesome/free-solid-svg-icons';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';

const FlagIcon = ({
    className,
    style,
    ...props
}: Omit<FontAwesomeIconProps, 'icon'>) => {
    return (
        <FontAwesomeIcon
            className={className}
            icon={faFlag}
            style={style}
            {...props}
        />
    );
};

export default FlagIcon;