import React from 'react';
import { Route } from 'react-router-dom';
import { useAuth } from '../providers/CustomAuthProvider';
import NotEnoughAccess from '../pages/error/NotEnoughAccess';
import NotAuthorised from '../pages/error/NotAuthorised';
const PrivateRoute = ({ layout: Layout, component: Component, AdminAccessOnly = false, ...rest }) => {
    
    const { loginWithRedirect, isAuthenticated, isAdmin, hasInsightAccess } = useAuth();

    return (
        <Layout isAdmin={isAdmin} isAuthorised={hasInsightAccess}>
            <Route
            {...rest}
            render={(props) => {
                if (!isAuthenticated) {
                    return loginWithRedirect({});
                }
                if (!hasInsightAccess) {
                    return <NotAuthorised />;
                }
                if( AdminAccessOnly && !isAdmin )
                {
                    return <NotEnoughAccess />;
                }

                return <Component {...props} />;
            }}
        />
        </Layout>
    );
};

export default PrivateRoute;
