import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button} from "react-bootstrap";
import { DEV_URL, rget } from "../../../utils/requests";
import { useHistory, useLocation } from "react-router-dom";
import { UserTable } from "./UserTable";
import Div from "../../../components/Div";
import PageTitle from "../../../components/PageTitle/index";
import PlusIcon from "../../../components/icons/Plus";
import { StatusCodes } from '../types/StatusCodes';
import { useApiFilters } from "hooks/useApiFilters";
import useToggle from "hooks/useToggle";
import Text from "../../../components/Text";
import { UserFilters } from "./UserFilters";
import UserFormModal from '../UserFormModal'
import { useAuth } from "../../../providers/CustomAuthProvider";

const UserList = () => {
  

  const history = useHistory();
  const location = useLocation();
  const { clientId } = useAuth()
  const { isOpen, show, hide } = useToggle();

  const [extra, setExtra] = useState();
  const [userData, setUserData] = useState([]);
  const [userDataCount, setUserDataCount] = useState(0);
//     const [companyDataPage, setCompanyDataPage] = useState(1);
//     const [totalRecords, setTotalRecords] = useState('');
//   const [companyDataPerPage, setCompanyDataPerPage] = useState(50);
  const [loadingUserData, setLoadingUserData] = useState(false);
//     const [searchText, setSearchText] = useState("");
  const [emailAddress, setEmailAddress] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [statusCode, setStatusCode] = useState('');
  const [role, setRole] = useState('');
//     const [licenseType, setlicenseType] = useState("");
  const searchParams = new URLSearchParams(location.search);
  let defaultPage = 1;
  if (searchParams.get("PageNo")) {
      defaultPage = searchParams.get("PageNo");
  }
    
  const {
    filters,
    orderBy,
    ascendingOrder,
    limit,
    page,
    search,
    setPage,
    setSearch,
    hook,
    setFilters
  } = useApiFilters({
      page: parseInt(defaultPage) - 1, limit: 50, filters: {}
  });

  // if present in query param, update state & Filters
  const onFilterApply = async (data) => {
    const isEmptyOrNull = (value) => {
      return (value=='' || value==null || value == undefined);
    }

    const searchParams = new URLSearchParams(location.search);
    setLoadingUserData(true);

    if (!isEmptyOrNull(data.emailAddress)) {
      searchParams.set('EmailAddress', data.emailAddress);
      setEmailAddress(searchParams.get("EmailAddress"));
    } else {
      searchParams.delete('EmailAddress');
      setEmailAddress('');
    }

    if (!isEmptyOrNull(data.firstName)) {
      searchParams.set('FirstName', data.firstName);
      setFirstName(searchParams.get("FirstName"));
    } else {
      searchParams.delete('FirstName');
      setFirstName('');
    }

    if (!isEmptyOrNull(data.lastName)) {
      searchParams.set('LastName', data.lastName);
      setLastName(searchParams.get("LastName"));
    } else {
      searchParams.delete('LastName');
      setLastName('');
    }

    if (!isEmptyOrNull(data.role)) {
      searchParams.set('Role', data.role);
      setRole(searchParams.get("Role"));
    } else {
      searchParams.delete('Role');
      setRole('');
    }
    if (!isEmptyOrNull(data.statusCode)) {
      searchParams.set('StatusCode', data.statusCode);
      setStatusCode(searchParams.get("StatusCode"));
    } else {
      searchParams.delete('StatusCode');
      setStatusCode('');
    }
    if (!isEmptyOrNull(data.search)) {
      searchParams.set('Search', data.search);
      setSearch(searchParams.get("Search"));
    } else {
      searchParams.delete('Search');
      setSearch('');
    }

    searchParams.delete("PageNo");
    setPage(0);

    history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
    });
    const queryParams = {
      PageNumber: page + 1, 
      PageSize: limit, 
      SearchTerm: data.search,
      FirstName: data.firstName,
      LastName: data.lastName,
      EmailAddress: data.emailAddress,
      StatusCode: StatusCodes[data?.statusCode],
      Role: data.role,
      orderBy: orderBy === undefined ? null : orderBy,
      ascendingOrder
    };

    const userDataResponse = await rget(
      `${DEV_URL}/api/client/${clientId}/users`,
      { params: queryParams }
    );
    setSearch(data.search?.trim());
    setUserData(userDataResponse.data.users);
    setUserDataCount(userDataResponse.data.totalRecords);
    setLoadingUserData(false);

  }
    
  useEffect(async () => {
    const fetchdata = async () => {
      let localEmailAddress = emailAddress;
      let localFirstName = firstName;
      let localLastName = lastName;
      let localRole = role;
      let localStatusCode = statusCode;
      let localSearch = search;
      
      let _filter = { ...filters };
      if (searchParams.get("EmailAddress")) {
        setEmailAddress(searchParams.get("EmailAddress"));
        localEmailAddress = searchParams.get("EmailAddress");
        _filter.emailAddress = localEmailAddress;
      }
      if (searchParams.get("FirstName")) {
        setFirstName(searchParams.get("FirstName"));
        localFirstName = searchParams.get("FirstName");
        _filter.firstName = localFirstName;
      }
      if (searchParams.get("LastName")) {
        setLastName(searchParams.get("LastName"));
        localLastName = searchParams.get("LastName");
        _filter.lastName = localLastName;
      }
      if (searchParams.get("Role")) {
        setRole(searchParams.get("Role"));
        localRole = searchParams.get("Role");
        _filter.role = localRole;
      }
      if (searchParams.get("StatusCode")) {
        setStatusCode(searchParams.get("StatusCode"));
        localStatusCode = searchParams.get("StatusCode");
        _filter.statusCode = localStatusCode;
      }
      if (searchParams.get("Search")) {
        setSearch(searchParams.get("Search"));
        localSearch = searchParams.get("Search");
        _filter.search = localSearch;
      }
      setFilters(_filter);
      setLoadingUserData(true);

      const queryParams = {
        PageNumber: page + 1, 
        PageSize: limit, 
        SearchTerm: search, 
        FirstName: localFirstName,
        LastName: lastName,
        EmailAddress: localEmailAddress,
        Role: role,
        StatusCode: StatusCodes[statusCode],
        orderBy: orderBy === undefined ? null : orderBy, 
        ascendingOrder
      };
      const userDataResponse = await rget(
          `${DEV_URL}/api/client/${clientId}/users`,
          { params: queryParams }
      );

      setExtra({ onApply: onFilterApply });
      setUserData(userDataResponse?.data?.users || []);
      setUserDataCount(userDataResponse?.data?.totalRecords || '');
      setLoadingUserData(false);
    }
    await fetchdata();
  }, [search, limit, page, search, orderBy, ascendingOrder]);
    
  return (
    <>
      <Div className="d-flex align-items-center justify-content-between">
          <div className="d-flex">
              <PageTitle title={`Users`} />
              <Text variant="subtitle" className="ms-2" style={{marginTop:"28px"}}>{userDataCount}</Text>
          </div>
        <Button className="btn btn-primary mb- fs-11" onClick={show}>
          <PlusIcon className="me-1" /> Create User
        </Button>
      </Div>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col sm="12">
                  {userData && 
                    <UserTable
                      users={userData}
                      loading={loadingUserData}
                      total={userDataCount}
                      hook={hook}
                      filters={UserFilters}
                      extra={extra}
                      onApiCallResponse={async () => await onFilterApply({}) }
                      />
                    }
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <UserFormModal isOpen={isOpen} hide={hide} afterSubmitCallback={async () => await onFilterApply({}) } />
    </>
  );
};

export default UserList;
