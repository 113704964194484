import axios from "axios";
import configJson from "clientAppSettings.json";
import configJsonDev from "clientAppSettings.Development.json";

var config = configJson;
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    config = configJsonDev;
} 


export const DEV_URL = config.API_URL;

const axiosInstance = axios.create();

// response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const rget = async (endpoint, configOptions = {}) => {
  try {
    const jwt = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${jwt}` },
    };

    config = {...config, ...configOptions};
    return await axiosInstance.get(endpoint, config);
  } catch (error) {
    return error?.response;
  }
};

export const rpost = async (endpoint, data) => {
  try {
    const jwt = localStorage.getItem("token");
    const config = {
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axiosInstance.post(endpoint, data, config);
    return response;
  } catch (error) {
    return error?.response;
  }
};

export const rput = async (endpoint, data) => {
  try {
    const jwt = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await axiosInstance.put(endpoint, data, config);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const rdelete = async (endpoint, configOptions = {}) => {
  try {
    const jwt = localStorage.getItem("token");
    let config = {
      headers: { Authorization: `Bearer ${jwt}` },
    };

    config = { ...config, ...configOptions };  // Merge custom options if passed

    const response = await axiosInstance.delete(endpoint, config);
    return response;
  } catch (error) {
    return error.response;
  }
};
