import { useState, useEffect, memo } from 'react';
import { useAuth } from '../../providers/CustomAuthProvider';
import { DEV_URL, rget } from "../../utils/requests";
import { Card, Row, Col } from 'react-bootstrap';
import Table, { LinkColumn } from 'components/Table/index';
import { ColumnDef } from "@tanstack/table-core";

type ISubscriptionData = {
    id: string;
    legalName: string;
    secureId: string;
    companyId: string;
};

const SubscribedCompanies = memo(() => {
    const { user, clientId } = useAuth();
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        const fetchSubscribedCompanies = async () => {
            const queryParams = {
                secureId: user?.sub
            };

            try {
                setIsLoading(true);
                const userDataResponse = await rget(
                    `${DEV_URL}/api/client/${clientId}/user/subscribed-companies`,
                    { params: queryParams }
                );
                setIsLoading(false);
                setSubscriptionList(userDataResponse.data);
            } catch (error) {
                console.error("Error fetching subscribed companies:", error);
            }
        };

        if (user && clientId) {
            fetchSubscribedCompanies();
        }
    }, []);

    const columns: ColumnDef<ISubscriptionData>[] = [
        {
            header: "Company Name",
            accessorKey: "legalName",
            enableSorting: false,
            cell: (props) => {
                return <LinkColumn
                value={props.row.original.legalName}
                url={`/companies/${props?.row?.original?.companyId}`}
            />;
            }
        }
    ];
    return (
        <div>
            <Row>
                <Col md={4}>
                    <Card>
                        <Card.Body>
                        <Table<ISubscriptionData> 
                            columns={columns} 
                            data={subscriptionList} 
                            enableFilters={false} 
                            enableSearch={false} 
                            enableHideColumns={false} 
                            enablePagination={false}
                            loading={isLoading}
                        />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            
        </div>
    );
});

export default SubscribedCompanies;
