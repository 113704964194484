import React from 'react';
import Select from 'react-select/async'; // Import the correct Async version
import { Form } from 'react-bootstrap';

export interface Option {
  value: any;
  label: string;
}

interface SearchableSelectProps {
  value: Option | null;
  onChange: (selectedOption: Option | null) => void;
  placeholder?: string;
  loadOptions: (inputValue: string) => Promise<Option[]>;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({
  value,
  onChange,
  placeholder = 'Search...',
  loadOptions,
}) => {
  return (
    <Form.Group controlId="searchable-select">
      <Select
        value={value}
        onChange={onChange}
        loadOptions={loadOptions}
        placeholder={placeholder}
        isClearable
        isSearchable
        cacheOptions
        defaultOptions
        classNamePrefix="react-select"
      />
    </Form.Group>
  );
};

export default SearchableSelect;