import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type TextLineProps = {
    primary?: string;
    secondary?: string | number | ReactNode;
    url?: string;
    labelClass?: string;
    isExternalUrl?: boolean;
};

const TextLine = ({ primary, secondary, url,labelClass, isExternalUrl = false }: TextLineProps) => {
    return (
        <span>
            {primary && <b className={labelClass}>{primary}</b>}
            {primary && secondary && ' '}
            {secondary && !url && <span>{secondary}</span>}
            {secondary && url && (
                isExternalUrl ? <a href={url} target="_blank" className="fs-11">
                        {secondary}
                    </a>
                    : <Link to={url} target="_blank" className="fs-11">
                        {secondary}
                    </Link>
            )}
        </span>
    );
};

export default TextLine;
