import Placeholder from 'react-bootstrap/Placeholder';
import FormLabel from 'react-bootstrap/FormLabel';

import Div from 'components/Div';

type SkeletonInputProps = {
    label?: string;
};

const SkeletonInput = ({ label }: SkeletonInputProps) => {
    return (
        <Div className="placeholder-wave">
            {label ? (
                <FormLabel>{label}</FormLabel>
            ) : (
                <Placeholder as={FormLabel} className="col-6">
                    a
                </Placeholder>
            )}
            <Placeholder className="col-12 form-control">a</Placeholder>
        </Div>
    );
};

export default SkeletonInput;
