import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import {  ReactNode, useEffect, useState } from 'react';


export type Tab = {
    title: string;
    icon?: string;
    url: string;
    iconComponent?: ReactNode;
    statusComponent?: ReactNode;
    hidden?: boolean;
    content?: ReactNode;
};

type TabsContainerProps = {
    tabs: Tab[];
};

const TabsContainer = ({ tabs }: TabsContainerProps) => {
    const { pathname } = useLocation();

    const [activeKey, setActiveKey] = useState<string | null>(null);

    // Set the initial active key based on the current path
    useEffect(() => {
        const activeTab = tabs.find((tab) => pathname.startsWith(tab.url))?.title;
        setActiveKey(activeTab || null);
    }, [pathname, tabs]);

    return (
        <Tab.Container
            activeKey={activeKey}
        >
            <Nav variant="tabs" className="nav-bordered" as="ul">
                {tabs
                    .filter((tab) => !tab.hidden)
                    .map((tab, index) => {
                        return (
                            <Nav.Item key={index.toString()} as="li">
                                <Nav.Link
                                    as={Link}
                                    to={tab.url}
                                    eventKey={tab.title}
                                >
                                    {tab.icon && (
                                        <i
                                            className={classNames(
                                                tab.icon,
                                                'd-inline'
                                            )}
                                        ></i>
                                    )}
                                    {tab.iconComponent}
                                    <span className="d-none d-md-inline ms-1">
                                        {tab.title}
                                    </span>
                                    <span className="d-none d-md-inline ms-1">
                                        {tab.statusComponent}
                                    </span>
                                </Nav.Link>
                            </Nav.Item>
                        );
                    })}
            </Nav>

            <Tab.Content>
                {tabs
                    .filter((tab) => !tab.hidden)
                    .map((tab, index) => {
                        return (
                            <Tab.Pane
                                eventKey={tab.title}
                                id={tab.title}
                                key={index.toString()}
                            >
                                {/* Only render the content of the active tab */}
                                {activeKey === tab.title && tab.content}
                            </Tab.Pane>
                        );
                    })}
            </Tab.Content>
        </Tab.Container>
    );
};

export default TabsContainer;
