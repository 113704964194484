import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Form, Tab, Nav } from "react-bootstrap";
import { DEV_URL, rpost, rget } from "../../utils/requests";
import DataTable from "react-data-table-component";
import { useHistory, useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";
import { ColumnDef } from "@tanstack/table-core";
import { CompaniesDataTable } from "./CompaniesDataTable.tsx";
import Div from "../../components/Div";
import PageTitle from "../../components/PageTitle/index";
import PlusIcon from "../../components/icons/Plus";
import { useApiFilters } from "hooks/useApiFilters";
import Text from "../../components/Text";
import { CompanyFilters } from "./CompanyFilter";


const CompaniesData = () => {
    const [extra, setExtra] = useState();
    const history = useHistory();
    const location = useLocation();
  const [companyData, setCompanyData] = useState([]);
  const [companyDataCount, setCompanyDataCount] = useState([]);
    const [companyDataPage, setCompanyDataPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState('');
  const [companyDataPerPage, setCompanyDataPerPage] = useState(50);
  const [loadingCompanyData, setLoadingCompanyData] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [stateCode, setStateCode] = useState("");
    const [licenseType, setlicenseType] = useState("");
    const searchParams = new URLSearchParams(location.search);
    let defaultPage = 1;
    if (searchParams.get("PageNo")) {
        defaultPage = searchParams.get("PageNo");
    }
    
    const {
        filters,
    orderBy,
    ascendingOrder,
    limit,
    page,
        search,
        setPage,
    setSearch,
      hook,
      setFilters
  } = useApiFilters({
      page: parseInt(defaultPage) - 1, limit: 50, filters: {
          commodity: '0',
          search: '',
          state: '',
          licenseType:''
      }
  });
  const paginationComponentOptions = {
    rowsPerPageText: "Rows per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
    };

  const handleCompanyDataPageChange = (companyDataPage) => {
      setCompanyDataPage(companyDataPage);
  };

  const handleCompanyDataPerRowsChange = (companyDataPerPage) => {
      setCompanyDataPerPage(companyDataPerPage);
    };
    const setPageNo = (p, s, lt) => {
        const searchParams = new URLSearchParams(location.search);
        let _filter = { ...filters }
        if (s !== '') {
            searchParams.set("StateCode", s);
            _filter.state = s;
        }
        if (lt !== '') {
            searchParams.set("LicenseType", lt);
            _filter.licenseType = lt;
        }
        if (p > 0) {
            searchParams.set("PageNo", parseInt(p) + 1);
            
        } else {
            searchParams.delete("PageNo");
        }
        setFilters(_filter);
        history.push({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }
    const onFilterApply = async (data) => {
        setLoadingCompanyData(true);
        const searchParams = new URLSearchParams(location.search);
        if (data.state !== '' && data.state !== null) {
            searchParams.set('StateCode', data.state);
            setStateCode(searchParams.get("StateCode"));
        } else {
            searchParams.delete('StateCode');
            setStateCode('');
        }
        if (data.licenseType !== '' && data.licenseType !== null) {
            setlicenseType(searchParams.get("LicenseType"));
            searchParams.set('LicenseType', data.licenseType);
        } else {
            setlicenseType('');
            searchParams.delete('LicenseType');
        }
        searchParams.delete("PageNo");
        setPage(0);
        history.push({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
        const companyDataResponse = await rpost(
            `${DEV_URL}/api/company/getall`,
            { PageNumber: 1, PageSize: limit, SearchTerm: data.search?.trim(), orderBy: orderBy === undefined ? null : orderBy, ascendingOrder, stateCode: data.state, licenseType: data.licenseType, commodity: data?.commodity??'0' }
        );
        setSearch(data.search?.trim());
        setTotalRecords(`${companyDataResponse.data.totalRecords} records`);
        setCompanyData(companyDataResponse.data.company);
        setCompanyDataCount(companyDataResponse.data.totalRecords);
        setLoadingCompanyData(false);

    }
    
    useEffect(() => {
        const fetchdata = async () => {
            let localState = stateCode;
            let localLicenseType = licenseType;
            setPageNo(page, stateCode, licenseType);
            let _filter = { ...filters };
            if (searchParams.get("StateCode")) {
                setStateCode(searchParams.get("StateCode"));
                localState = searchParams.get("StateCode");
                _filter.state = localState;
            }
            if (searchParams.get("LicenseType")) {
                setlicenseType(searchParams.get("LicenseType"));
                localLicenseType = searchParams.get("LicenseType");
                _filter.licenseType = localLicenseType;
            }
            setFilters(_filter);
            setLoadingCompanyData(true);
            const companyDataResponse = await rpost(
                `${DEV_URL}/api/company/getall`,
                { PageNumber: page + 1, PageSize: limit, SearchTerm: search, orderBy: orderBy === undefined ? null : orderBy, ascendingOrder, stateCode: localState, licenseType: localLicenseType,commodity: '0' }
            );
            const response = await rget(
                `${DEV_URL}/api/address/GetStates`
            );
            if (response !== 'Data not found') {
                response.data.splice(0, 0, { id: '', name: 'All', code: '' })
                setExtra({ stateList: response.data, onApply: onFilterApply, setCompanyData:setCompanyData });
            }
            setTotalRecords(`${companyDataResponse?.data?.totalRecords} records`);
            setCompanyData(companyDataResponse?.data?.company);
            setCompanyDataCount(companyDataResponse?.data?.totalRecords);
            setLoadingCompanyData(false);

            return () => {
                setCompanyData();
                setCompanyDataCount();
            };
        }
        fetchdata();
  }, [companyDataPage, companyDataPerPage, searchText, limit, page, search, orderBy, ascendingOrder]);
    
  return (
    <>
          <Div className="d-flex align-items-center justify-content-between">
              <div className="d-flex">
                  <PageTitle title={`Companies`} />
                  <Text variant="subtitle" className="ms-2" style={{marginTop:"28px"}}>{totalRecords}</Text>
              </div>
        <Button className="btn btn-primary mb- fs-11">
          Create Company
        </Button>
      </Div>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col sm="12">
                  {companyData && (
                    <CompaniesDataTable
                                          companies={companyData}
                                          loading={loadingCompanyData}
                                          total={companyDataCount}
                                          hook={hook}
                                          filters={CompanyFilters}
                                          extra={extra}
                    />
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CompaniesData;
