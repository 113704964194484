import { Row, Col, Button, Form } from "react-bootstrap";
import { trimInvalidDate } from 'helpers/api/utils';
import LoadingIndicator from 'components/LoadingIndicator';
import { ColumnDef } from "@tanstack/table-core";
import RowBS from 'react-bootstrap/Row';
import Col2 from 'react-bootstrap/Col';
import {
    getCoreRowModel,
    useReactTable,
    SortingState,
    RowSelectionState,
    flexRender
} from '@tanstack/react-table';
import React, { useEffect, useState } from "react";
import Pagination from "components/Table/Pagination";
import { getTablePaginationSizes } from 'utils/get-table-pagination-sizes'
import classNames from "classnames";
import Div from "components/Div";
import SortingIcon from "components/Table/SortingIcon";
import SearchTable from "components/Table/SearchTable";
import FiltersIcon from "components/icons/Filters";
import Drawer from "components/Drawer";
import Filters from "components/icons/Filters";
import ColumnToggler from "components/Table/ColumnToggler";
import ThreeDotMenu from "components/ThreeDotMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import FormInput from "components/FormInput";
import { type SubmitHandler, useForm } from "react-hook-form";
export const NotificationsData = ({ total, hook, companyId, notifications, setNotificationsList,pageSizes, loading, noDataMessage,markById,removeById,onSearch,onViewMessage}) => {

    type INotificationFilters = {
        search:string,
        from:any,
        to:any
    }
    const emptyFilter :INotificationFilters ={
        search : '',
        from : '',
        to :''
    }
    const {
            handleSubmit,
            register,
            resetField,
            formState: { errors },
            reset,
        } = useForm <INotificationFilters> ({
            defaultValues: { ...emptyFilter }
        });
    const sizes = getTablePaginationSizes(
        total,
        true,
        ...pageSizes
    );

    
    const {
        limit,
        setLimit,
        page,
        setPage,
        search,
        setSearch,
        orderBy,
        setOrderBy,
        ascendingOrder,
        setAscendingOrder,
        filters,
        setFilters
    } = hook();
    const [sorting, setSorting] = useState<SortingState>(
        orderBy ? [{ id: String(orderBy), desc: !ascendingOrder }] : []
    );
    
    const customStyles = {
        header: {
            style: {
                backgroundColor: "#eef2f7",
                fontSize: "11px",
                color: "#474d56",
                minHeight: "28px"
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eef2f7",
                fontSize: "11px",
                color: "#474d56",
                minHeight: "28px"
            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    backgroundColor: "#eef2f7",
                    fontSize: "11px",
                    color: "#474d56",
                    minHeight: "28px"
                },
            },
        },
        rows: {
            style: {
                minHeight: "28px",
                fontSize: "11px"
            }
        }
    }
    const NotificationsDataColumns: ColumnDef<any>[] = [
        // {
        //     header: "Source",
        //     cell: (row) => <div title="System Generated">System Generated</div>,
        //     enableSorting: false,
        // },
        {
            header: "Date",
            accessorKey: "CreatedOn",
            cell: (row) => trimInvalidDate(row.row.original.created.split("T")[0]),
            enableSorting: true,
        },
        // {    `
        //     header: "Status",
        //     cell: (row) => <div dangerouslySetInnerHTML={{ __html: `<span style="font-size:10px" class="px-2 font-monospace ${row.row.original.isRead==0?'badge bg-info':'badge bg-success'} ">${row.row.original.isRead==0?'New':'Completed'}</span>` }}></div>,
        //     enableSorting: false,
        // },
        {
            header: "Description",
            accessorKey: "message",
            cell: (row) => row.row.original.isRead==0 ? <strong><span dangerouslySetInnerHTML={{__html:row.row.original.message}}></span></strong> : <span dangerouslySetInnerHTML={{__html:row.row.original.message}}></span>,
            enableSorting: false,
        },
        // {
        //     header: "Action",
        //     accessorKey:"",
        //     cell: (row)=> <ThreeDotMenu className="text-center">
        //     <DropdownItem onClick={async (e) => {
        //       let res = await removeById(row.row.original.id,false);
        //       if(res){
        //         const newData = [...notifications];
        //         newData.splice(row.row.index,1);
        //         setNotificationsList(newData);
        //       }
        //     }}><small>Remove</small></DropdownItem>
        //     <DropdownItem onClick={async (e) => {
        //       let res = await markById(row.row.original.id);
        //       if(res){
        //         const newData = [...notifications];
        //         newData[row.row.index].isRead = 0;
        //         setNotificationsList(newData);
        //       }
        //     }}><small>Mark Unread</small></DropdownItem>
        // </ThreeDotMenu>
        // }
    ]
    const table = useReactTable({
        data: notifications,
        columns: NotificationsDataColumns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            sorting
        },
        onSortingChange: setSorting,
        manualSorting: true,
        manualPagination: true
    });
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] =
        useState<boolean>(false);
    const openFilterDrawer = () => setIsFilterDrawerOpen(true);
    const closeFilterDrawer = () => setIsFilterDrawerOpen(false);



    useEffect(() => {
        const req = async ()=>{
            setOrderBy(sorting[0]?.id);
            setAscendingOrder(sorting.length > 0 ? !sorting[0]?.desc : false); 
        }
        req();
        
    }, [sorting]);

    const onSubmit: SubmitHandler<INotificationFilters> = async (data) => {
        await onSearch(data);
        closeFilterDrawer();
        };
    return (
        <>
        <RowBS className="mb-1">
                            <Col2 xl={5} xxl={3}>
                            </Col2>
                            <Col className="d-flex justify-content-end align-items-center">
                                {(
                                    <>
                                        <Button
                                            variant="primary"
                                            className="mx-1"
                                            onClick={openFilterDrawer}
                                        >
                                            <FiltersIcon />
                                        </Button>
                                        {isFilterDrawerOpen && (
                                            <Drawer
                                                isOpen={isFilterDrawerOpen}
                                                hide={closeFilterDrawer}
                                    content={
                                        <div className="p-1">
                                            <Form onSubmit={handleSubmit(onSubmit)}>
                                            <label className="mt-2">Search</label>
                                            <FormInput type="text"
                                            className="mt-2"
                                            placeholder="Search..."
                                            register={register}
                                            name="search"/>
                                            <label className="mt-2">Date From</label>
                                            <FormInput type="date"
                                                            className="mt-2"
                                            placeholder="From"
                                            register={register}
                                            name="from"/>
                                            <label className="mt-2">Date To</label>
                                                            <FormInput type="date"
                                                            className="mt-2"
                                            placeholder="To"
                                            register={register}
                                            name="to"/>      
                                            <div className="d-flex justify-content-between">
                                            <Button className="mt-2" type="submit">
                                                Search
                                            </Button>         
                                            <Button className="mt-2" type="reset" onClick={() => {resetField("search");resetField("from");resetField("to"); closeFilterDrawer(); }}>
                                                Reset
                                            </Button>
                                                </div>                 
                                            
                                            </Form>
                                            
                                                    </div>
                                                }
                                            />
                                        )}
                                    </>
                                )}
                                {(
                                    <ColumnToggler columns={table.getAllColumns()} />
                                )}
                            </Col>
                        </RowBS>
        <Row>
        <Col sm="12">
            {loading ? <div className="text-center py-1" >
                <LoadingIndicator/>
            </div> : (
                        <div className="table-responsive fs-11">
                            <table className={classNames('table table-centered react-table')} >
                                <thead className="table-light">
                                    {
                                        table.getHeaderGroups().map((headerGroup, index) => (
                                            <tr key={index}>
                                                {headerGroup.headers.map((header, index) => {
                                                    return (
                                                        <th key={`${header.id}-${index}`}>
                                                            <Div
                                                                style={{
                                                                    whiteSpace: 'normal'
                                                                }}
                                                                className={classNames(
                                                                    'd-flex',
                                                                    'justify-content-between',
                                                                    'align-items-center',
                                                                    {
                                                                        'cursor-pointer':
                                                                            header.column.getCanSort(),
                                                                        'select-none':
                                                                            header.column.getCanSort()
                                                                    }
                                                                )}
                                                                onClick={header.column.getToggleSortingHandler()}
                                                            >
                                                                {header.isPlaceholder
                                                                    ? null
                                                                    : flexRender(
                                                                        header.column
                                                                            .columnDef.header,
                                                                        header.getContext()
                                                                    )}
                                                                {
                                                                    header.column.getCanSort() && (
                                                                        <SortingIcon
                                                                            isSorted={header.column.getIsSorted()}
                                                                        />
                                                                    )}
                                                            </Div>
                                                        </th>
                                                    );
                                                })}
                                            </tr>
                                        ))
                                    }
                                </thead>
                                <tbody>
                                    {
                                       ((notifications?.length??0)>0 ?table.getRowModel().rows.map((row, index) => (
                                            <tr
                                                key={`${row.id}-${index}`}>
                                                {row
                                                    .getVisibleCells()
                                                    .map((cell, cellIndex) => (
                                                        <td key={`${cell.id}-${cellIndex}`}>
                                                            {flexRender(
                                                                cell.column.columnDef.cell,
                                                                cell.getContext()
                                                            )}
                                                        </td>
                                                    ))}
                                            </tr>
                                        )):<tr>
                                            <td colSpan={table.getVisibleFlatColumns().length}>
                                            <div className="text-center">
                        {noDataMessage}
                    </div>
                                            </td>
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div>
                    
            )}
            
            {(notifications?.length??0)>0 && <Pagination
                limit={limit}
                page={page}
                setPage={setPage}
                setLimit={setLimit}
                total={total}
                sizePerPageList={sizes}
                disabled={loading}
            />}
        </Col>
            </Row>
        </>)
}


