import Button, { ButtonProps } from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

type LoadingButtonProps = ButtonProps & {
    isLoading?: boolean;
    iconOrientation?: 'start' | 'end';
};

const LoadingButton = ({
    isLoading,
    iconOrientation = 'end',
    children,
    disabled,
    ...props
}: LoadingButtonProps) => {
    return (
        <Button disabled={isLoading || disabled} {...props}>
            {isLoading && iconOrientation === 'start' && (
                <Spinner
                    animation="border"
                    style={{ width: '11px', height: '11px' }}
                    size="sm"
                    role="status"
                    className="me-1"
                >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
            {children}
            {isLoading && iconOrientation === 'end' && (
                <Spinner
                    animation="border"
                    style={{ width: '11px', height: '11px' }}
                    role="status"
                    className="ms-1"
                >
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
        </Button>
    );
};

export default LoadingButton;
