import { PropsWithChildren, ReactNode } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Placement } from 'react-bootstrap/types';

type TableTooltipProps = {
    id?: string;
    title?: ReactNode;
    placement?: Placement;
} & PropsWithChildren;

const HyperTooltip = ({
    id,
    children,
    title,
    placement = 'top'
}: TableTooltipProps) => {
    if (!title) {
        return <>{children}</>;
    }

    return (
        <OverlayTrigger
            overlay={<Tooltip id={id}>{title}</Tooltip>}
            placement={placement}
        >
            <span>{children}</span>
        </OverlayTrigger>
    );
};

export default HyperTooltip;
