import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, Control } from 'react-hook-form';
import Select, { MenuPlacement } from 'react-select';
import classNames from 'classnames';

import Div from 'components/Div';

type HyperSelectProps = {
    name?: string;
    options: any[];
    value?: any;
    multiple?: boolean;
    placeholder?: string;
    labelKey?: string | ((option: any) => string);
    validation?: Record<string, any>;
    control?: Control<any>;
    disabled?: boolean;
    isInvalid?: boolean;
    onChange?: (...event: any[]) => void;
    uncontrolled?: boolean;
    menuPlacement?: MenuPlacement;
    closeOnSelect?: boolean;
    clearable?: boolean;
    className?: string;
};

const HyperSelect = ({
    name,
    control,
    options,
    multiple = false,
    placeholder,
    validation,
    labelKey = 'name',
    disabled,
    isInvalid,
    value,
    onChange,
    uncontrolled,
    menuPlacement = 'auto',
    closeOnSelect = true,
    clearable,
    className
}: HyperSelectProps) => {
    const customStyles = {
        control: (base) => ({
            ...base,
            minHeight: '33px',
            // state.isFocused can display different borderColor if you need it
            borderColor: isInvalid
                ? 'var(--ct-danger) !important'
                : 'var(--ct-input-border-color)',
            backgroundImage: isInvalid
                ? 'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23fa5c7c%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23fa5c7c%27 stroke=%27none%27/%3e%3c/svg%3e")'
                : 'none',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right calc(0.375em + 0.225rem) center',
            backgroundSize: 'calc(0.75em + 0.45rem) calc(0.75em + 0.45rem)',
            '&:hover': {
                borderColor: isInvalid
                    ? 'var(--ct-danger)'
                    : 'var(--ct-input-border-color)'
            },
            '& .react-select__single-value': {
                color: 'var(--ct-input-color) !important'
            },
            '& .react-select__placeholder': {
                color: 'var(--ct-input-placeholder-color) !important'
            },
            '& .react-select__indicators': {
                display: 'none'
            }
        }),
        menu: (base) => ({
            ...base,
            marginTop: 0,
            width: 'max-content',
            minWidth: '100%'
        }),
        menuPortal: (base) => ({
            ...base,
            zIndex: 9999
        })
    };

    if (uncontrolled) {
        return (
            <Select
                className={classNames({
                    'is-invalid': isInvalid
                })}
                styles={customStyles}
                classNamePrefix="react-select"
                isMulti={multiple}
                options={options}
                onChange={onChange}
                placeholder={placeholder}
                menuPortalTarget={document.body}
                value={value}
                getOptionLabel={
                    typeof labelKey === 'string'
                        ? (option) => option[labelKey]
                        : labelKey
                }
                getOptionValue={(option) => option['id']}
                isDisabled={disabled}
                menuPlacement={menuPlacement}
                closeMenuOnSelect={closeOnSelect}
            />
        );
    }

    return (
        <Controller
            control={control}
            name={name}
            defaultValue={value}
            rules={validation}
            render={({
                field: { onChange: onControllerChange, value, onBlur }
            }) => (
                <>
                    <Select
                        className={classNames(className, {
                            'is-invalid': isInvalid
                        })}
                        styles={customStyles}
                        classNamePrefix="react-select"
                        isMulti={multiple}
                        options={options}
                        onBlur={onBlur}
                        onChange={onControllerChange}
                        placeholder={placeholder}
                        menuPortalTarget={document.body}
                        value={value}
                        getOptionLabel={
                            typeof labelKey === 'string'
                                ? (option) => option[labelKey]
                                : labelKey
                        }
                        getOptionValue={(option) => option['id']}
                        isDisabled={disabled}
                        menuPlacement={menuPlacement}
                        closeMenuOnSelect={closeOnSelect}
                    />
                    {clearable && !!value && (
                        <Div
                            style={{
                                textAlign: 'end',
                                height: 0
                            }}
                        >
                            <FontAwesomeIcon
                                icon={faTimes}
                                onClick={() => onControllerChange(null)}
                                style={{
                                    position: 'relative',
                                    bottom: 25,
                                    right: 10,
                                    fontSize: 18,
                                    cursor: 'pointer'
                                }}
                            />
                        </Div>
                    )}
                </>
            )}
        />
    );
};

export default HyperSelect;
