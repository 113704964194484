import configJson from "./clientAppSettings.json";
import configJsonDev from "./clientAppSettings.Development.json";

export default function getConfig() {
    var config = configJson;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        config = configJsonDev;
    }

    const audience =
        config.Auth0.audience && config.Auth0.audience !== "YOUR_API_IDENTIFIER"
            ? config.Auth0.audience
            : null;

    return {
        domain: config.Auth0.domain,
        clientId: config.Auth0.clientId,
        ...(audience ? { audience } : null),
    };
}