import { InputHTMLAttributes } from 'react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import {
    AsyncTypeaheadInput,
    CheckboxRadioInput,
    CreatableSelectInput,
    DateInput,
    EditorInput,
    FileInput,
    GenericInput,
    GenericInputProps,
    InputType,
    MarginInput,
    MultiEmailInput,
    PasswordInput as PasswordInputType,
    SelectInput,
    TextareaInput,
    TextInput,
    TypeaheadInput,
    ZipInput as ZipInputType
} from './input.type';
import SkeletonInput from './SkeletonInput';
import PasswordInput from './PasswordInput';

// import HyperDatePicker from 'components/HyperDatePicker';
import HyperSelect from 'components/HyperSelect';
// import HyperTypeahead from 'components/HyperTypeahead';
import PhoneInput from 'components/PhoneInput';
// import Div from 'components/Div';
import ZipInput from 'components/ZipInput';
// import CustomCreatableSelect from 'components/CreatableSelect';
// import EditorHTML from 'components/Editor';
// import WorkPhoneInput from 'components/WorkPhoneInput';
// import MultiEmail from 'components/MultiEmail';
import HyperTooltip from 'components/Tooltip';
// import MarginInputComponent from 'components/MarginInput';

type FormInputProps = InputHTMLAttributes<HTMLInputElement> &
    PasswordInputType &
    CheckboxRadioInput &
    DateInput &
    CreatableSelectInput &
    SelectInput &
    TypeaheadInput &
    AsyncTypeaheadInput &
    TextareaInput &
    EditorInput &
    ZipInputType &
    TextInput &
    FileInput &
    MultiEmailInput &
    MarginInput &
    GenericInput;
function FormInput<T extends InputType>({
    type,
    ...props
}: { type: T } & GenericInputProps<T>);
function FormInput({
    label,
    type,
    name,
    placeholder,
    register,
    validation,
    errors,
    control,
    inputClassName,
    labelClassName,
    containerClass,
    refCallback,
    options,
    disabled,
    multiple,
    labelKey,
    isLoading,
    onSearch,
    icon,
    value,
    checked,
    menuPlacement,
    minDate,
    maxDate,
    rows,
    onCreateOption,
    onlyMonthPicker,
    datePickerMode,
    closeOnSelect,
    disabledDates,
    datesClassnames,
    enableSundays,
    handleEmails,
    clearable,
    tooltip,
    tooltipPlacement,
    prefix,
    sufix,
    ...otherProps
}: FormInputProps) {
    // handle input type
    const comp = type === 'textarea' ? 'textarea' : 'input';

    let input = <></>;

    if (isLoading) {
        return (input = <SkeletonInput label={label} />);
    }

    switch (type) {
        case 'hidden':
            input = (
                <input
                    type={type}
                    name={name}
                    {...(register ? register(name, { ...validation }) : {})}
                    {...otherProps}
                />
            );
            break;
        case 'password':
            input = (
                <Form.Group className={containerClass}>
                    {label ? (
                        <Form.Label className={labelClassName}>
                            {label}
                        </Form.Label>
                    ) : null}
                    <PasswordInput
                        name={name}
                        placeholder={placeholder}
                        refCallback={refCallback}
                        errors={errors}
                        register={register}
                        validation={validation}
                        className={inputClassName}
                    />

                    {errors && errors[name] ? (
                        <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                        >
                            {errors[name]['message'] as string}
                        </Form.Control.Feedback>
                    ) : null}
                </Form.Group>
            );
            break;
        case 'select':
            input = (
                <Form.Group className={containerClass}>
                    {label ? (
                        <Form.Label className={labelClassName}>
                            {label}
                        </Form.Label>
                    ) : null}
                    <HyperSelect
                        options={options}
                        name={name}
                        control={control}
                        value={value}
                        disabled={disabled}
                        multiple={multiple}
                        labelKey={labelKey}
                        placeholder={placeholder}
                        validation={validation}
                        isInvalid={errors && Boolean(errors[name])}
                        menuPlacement={menuPlacement}
                        closeOnSelect={closeOnSelect}
                        clearable={clearable}
                    />
                    {errors && errors[name] ? (
                        <Form.Control.Feedback type="invalid">
                            {errors[name]['message'] as string}
                        </Form.Control.Feedback>
                    ) : null}
                </Form.Group>
            );
            break;
        // case 'creatableSelect':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label ? (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             ) : null}
        //             <CustomCreatableSelect
        //                 options={options}
        //                 name={name}
        //                 control={control}
        //                 value={value}
        //                 disabled={disabled}
        //                 multiple={multiple}
        //                 labelKey={labelKey}
        //                 placeholder={placeholder}
        //                 validation={validation}
        //                 isInvalid={errors && Boolean(errors[name])}
        //                 menuPlacement={menuPlacement}
        //                 onCreateOption={onCreateOption}
        //             />
        //         </Form.Group>
        //     );
        //     break;
        // case 'typeahead':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label ? (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             ) : null}
        //             <HyperTypeahead
        //                 options={options}
        //                 name={name}
        //                 control={control}
        //                 disabled={disabled}
        //                 multiple={multiple}
        //                 labelKey={labelKey}
        //                 placeholder={placeholder}
        //                 validation={validation}
        //                 isInvalid={errors && Boolean(errors[name])}
        //             />
        //             {errors && errors[name] ? (
        //                 <Form.Control.Feedback type="invalid">
        //                     {errors[name]['message'] as string}
        //                 </Form.Control.Feedback>
        //             ) : null}
        //         </Form.Group>
        //     );
        //     break;
        // case 'asyncTypeahead':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label ? (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             ) : null}
        //             <HyperTypeahead
        //                 options={options}
        //                 name={name}
        //                 control={control}
        //                 disabled={disabled}
        //                 multiple={multiple}
        //                 labelKey={labelKey}
        //                 placeholder={placeholder}
        //                 validation={validation}
        //                 isInvalid={errors && Boolean(errors[name])}
        //                 aSync={true}
        //                 isLoading={isLoading}
        //                 onSearch={onSearch}
        //             />
        //             {errors && errors[name] ? (
        //                 <Form.Control.Feedback type="invalid">
        //                     {errors[name]['message'] as string}
        //                 </Form.Control.Feedback>
        //             ) : null}
        //         </Form.Group>
        //     );
        //     break;
        // case 'checkbox':
        // case 'radio':
        // case 'switch':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             <Form.Check
        //                 type={type}
        //                 label={
        //                     <Div className="ps-1">
        //                         {icon} {label}
        //                     </Div>
        //                 }
        //                 name={name}
        //                 id={otherProps.id}
        //                 ref={(r: HTMLInputElement) => {
        //                     if (refCallback) {
        //                         refCallback(r);
        //                     }
        //                 }}
        //                 disabled={disabled}
        //                 value={value}
        //                 checked={checked}
        //                 className={`d-flex align-items-center ${inputClassName}`}
        //                 isInvalid={errors && Boolean(errors[name])}
        //                 {...(register
        //                     ? register(name, {
        //                           ...validation
        //                       })
        //                     : {})}
        //                 {...otherProps}
        //             />

        //             {errors && errors[name] ? (
        //                 <Form.Control.Feedback type="invalid">
        //                     {errors[name]['message'] as string}
        //                 </Form.Control.Feedback>
        //             ) : null}
        //         </Form.Group>
        //     );
        //     break;
        // case 'date':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label && (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             )}
        //             <HyperDatePicker
        //                 name={name}
        //                 control={control}
        //                 placeholder={placeholder}
        //                 validation={validation}
        //                 disabled={disabled}
        //                 isInvalid={errors && Boolean(errors[name])}
        //                 minDate={minDate}
        //                 maxDate={maxDate}
        //                 mode={datePickerMode}
        //                 multiple={multiple}
        //                 onlyMonthPicker={onlyMonthPicker}
        //                 disabledDates={disabledDates}
        //                 datesClassnames={datesClassnames}
        //                 enableSundays={enableSundays}
        //             />
        //             {errors && errors[name] ? (
        //                 <Form.Control.Feedback type="invalid">
        //                     {errors[name]['message'] as string}
        //                 </Form.Control.Feedback>
        //             ) : null}
        //         </Form.Group>
        //     );
        //     break;
        // case 'textarea':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label ? (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             ) : null}
        //             <Form.Control
        //                 type="textarea"
        //                 as="textarea"
        //                 ref={(r: HTMLInputElement) => {
        //                     if (refCallback) {
        //                         refCallback(r);
        //                     }
        //                 }}
        //                 placeholder={placeholder}
        //                 name={name}
        //                 id={name}
        //                 className={inputClassName}
        //                 disabled={disabled}
        //                 isInvalid={errors && errors[name] ? true : false}
        //                 rows={rows}
        //                 {...(register
        //                     ? register(name, {
        //                           ...validation
        //                       })
        //                     : {})}
        //                 {...otherProps}
        //             />
        //             {errors && errors[name] ? (
        //                 <Form.Control.Feedback type="invalid">
        //                     {errors[name]['message'] as string}
        //                 </Form.Control.Feedback>
        //             ) : null}
        //         </Form.Group>
        //     );
        //     break;
        case 'phone':
            input = (
                <Form.Group className={containerClass}>
                    {label ? (
                        <Form.Label className={labelClassName}>
                            {label}
                        </Form.Label>
                    ) : null}
                    <PhoneInput
                        name={name}
                        className={inputClassName}
                        control={control}
                        validation={validation}
                        disabled={disabled}
                        isInvalid={errors && errors[name] ? true : false}
                    />
                    {errors && errors[name] ? (
                        <Form.Control.Feedback type="invalid">
                            {errors[name]['message'] as string}
                        </Form.Control.Feedback>
                    ) : null}
                </Form.Group>
            );
            break;
        // case 'workPhone':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label ? (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             ) : null}
        //             <WorkPhoneInput
        //                 name={name}
        //                 className={inputClassName}
        //                 control={control}
        //                 validation={validation}
        //                 disabled={disabled}
        //                 isInvalid={errors && errors[name] ? true : false}
        //             />
        //             {errors && errors[name] ? (
        //                 <Form.Control.Feedback type="invalid">
        //                     {errors[name]['message'] as string}
        //                 </Form.Control.Feedback>
        //             ) : null}
        //         </Form.Group>
        //     );
        //     break;
        case 'zipCode':
            input = (
                <Form.Group className={containerClass}>
                    {label ? (
                        <Form.Label className={labelClassName}>
                            {label}
                        </Form.Label>
                    ) : null}
                    <ZipInput
                        name={name}
                        className={inputClassName}
                        control={control}
                        validation={validation}
                        disabled={disabled}
                        isInvalid={errors && errors[name] ? true : false}
                    />
                    {errors && errors[name] ? (
                        <Form.Control.Feedback type="invalid">
                            {errors[name]['message'] as string}
                        </Form.Control.Feedback>
                    ) : null}
                </Form.Group>
            );
            break;
        // case 'editor':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label ? (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             ) : null}
        //             <EditorHTML name={name} control={control} />
        //         </Form.Group>
        //     );
        //     break;
        // case 'multiEmail':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label && (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             )}
        //             <MultiEmail
        //                 name={name}
        //                 control={control}
        //                 placeholder={placeholder}
        //                 validation={validation}
        //                 options={options}
        //                 handleEmails={handleEmails}
        //                 multiple={multiple}
        //             />
        //             {errors && errors[name] ? (
        //                 <Form.Control.Feedback type="invalid">
        //                     {errors[name]['message'] as string}
        //                 </Form.Control.Feedback>
        //             ) : null}
        //         </Form.Group>
        //     );
        //     break;
        case 'dollar':
            input = (
                <Form.Group className={containerClass}>
                    {label ? (
                        <Form.Label className={labelClassName}>
                            {label}
                        </Form.Label>
                    ) : null}
                    <InputGroup>
                        <InputGroup.Text>$</InputGroup.Text>
                        <Form.Control
                            type="number"
                            ref={(r: HTMLInputElement) => {
                                if (refCallback) {
                                    refCallback(r);
                                }
                            }}
                            placeholder={placeholder}
                            name={name}
                            id={name}
                            className={inputClassName}
                            disabled={disabled}
                            isInvalid={errors && errors[name] ? true : false}
                            {...(register
                                ? register(name, {
                                      ...validation
                                  })
                                : {})}
                            {...otherProps}
                        />
                        {errors && errors[name] ? (
                            <Form.Control.Feedback type="invalid">
                                {errors[name]['message'] as string}
                            </Form.Control.Feedback>
                        ) : null}
                    </InputGroup>
                </Form.Group>
            );
            break;
        // case 'margin':
        //     input = (
        //         <Form.Group className={containerClass}>
        //             {label ? (
        //                 <Form.Label className={labelClassName}>
        //                     {label}
        //                 </Form.Label>
        //             ) : null}
        //             <MarginInputComponent
        //                 disabled={disabled}
        //                 name={name}
        //                 register={register}
        //                 prefix={prefix}
        //                 sufix={sufix}
        //                 {...otherProps}
        //             />
        //             {errors && errors[name] ? (
        //                 <Form.Control.Feedback type="invalid">
        //                     {errors[name]['message'] as string}
        //                 </Form.Control.Feedback>
        //             ) : null}
        //         </Form.Group>
        //     );
        //     break;
        default:
            input = (
                <Form.Group className={containerClass}>
                    {label ? (
                        <Form.Label className={labelClassName}>
                            {label}
                        </Form.Label>
                    ) : null}
                    <Form.Control
                        type={type}
                        placeholder={placeholder}
                        value={value}
                        name={name}
                        id={name}
                        as={comp}
                        ref={(r: HTMLInputElement) => {
                            if (refCallback) {
                                refCallback(r);
                            }
                        }}
                        className={inputClassName}
                        isInvalid={errors && errors[name] ? true : false}
                        {...(register
                            ? register(name, {
                                  ...validation
                              })
                            : {})}
                        {...otherProps}
                        autoComplete={name}
                        disabled={disabled}
                        onWheel={(e) => {
                            // Prevent the input value change
                            e.target?.['blur']();

                            // Prevent the page/container scrolling
                            e.stopPropagation();

                            // Refocus immediately, on the next tick (after the current     function is done)
                            setTimeout(() => {
                                e.target?.['focus']();
                            }, 0);
                        }}
                    />

                    {errors && errors[name] ? (
                        <Form.Control.Feedback type="invalid">
                            {errors[name]['message'] as string}
                        </Form.Control.Feedback>
                    ) : null}
                </Form.Group>
            );
    }

    if (tooltip) {
        return (
            <HyperTooltip title={tooltip} placement={tooltipPlacement}>
                {input}
            </HyperTooltip>
        );
    } else {
        return <>{input}</>;
    }
}

export default FormInput;
