// @flow
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { SideBarWidth } from './../types/sidebar-width.enum';
import { LocalStorageValues } from './../types/localstorage';
import { changeBodyAttribute } from './../utils/change-body-attribute.ts'
// actions
import { changeSidebarType, changeSidebarTheme } from '../redux/actions';

import * as layoutConstants from '../constants/layout';

// components
import ThemeCustomizer from '../components/ThemeCustomizer';
import { getValue, setValue } from '../utils/localstorage';

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import('./Topbar'));
const LeftSidebar = React.lazy(() => import('./LeftSidebar'));
const Footer = React.lazy(() => import('./Footer'));
const RightSidebar = React.lazy(() => import('./RightSidebar'));

const loading = () => <div className=""></div>;

type VerticalLayoutProps = {
    children?: any,
    isAdmin: boolean, 
    isAuthorised: boolean
};

type VerticalLayoutState = {
    isMenuOpened?: boolean,
    sideBarWidth: SideBarWidth.CONDENSED,
};

const VerticalLayout = ({ children, isAdmin, isAuthorised }: VerticalLayoutProps, state: VerticalLayoutState): any => {


    const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
    const [sideBarWidth, _setSideBarWidth] = useState<SideBarWidth>(
        (getValue(LocalStorageValues.LAYOUT_SIDEBAR_WIDTH) as SideBarWidth) ||
        SideBarWidth.CONDENSED
    );
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [height, setHeight] = useState<number>(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };
    /**
     * Open the menu when having mobile screen
     */
    const openMenu = () => {
        _setSideBarWidth(sideBarWidth === SideBarWidth.CONDENSED ? SideBarWidth.FIXED : SideBarWidth.CONDENSED);
        setValue(LocalStorageValues.LAYOUT_SIDEBAR_WIDTH, sideBarWidth === SideBarWidth.CONDENSED ? SideBarWidth.FIXED : SideBarWidth.CONDENSED);
        sideBarToggle();
        setIsMenuOpened(!isMenuOpened);
        if (document.body) {
            if (isMenuOpened) {
                document.body.classList.remove('sidebar-enable');
            } else {
                document.body.classList.add('sidebar-enable');
            }
        }
    };
    const sideBarToggle = () => {
        handleWindowResize();
        if (sideBarWidth === SideBarWidth.FIXED) {
            if (width < 768) {
                changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.FIXED);
            } else {
                changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.CONDENSED);
            }
        } else {
            changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.FIXED);
        }
    }

    const updateDimensions = useCallback(() => {

        handleWindowResize();
        // activate the condensed sidebar if smaller devices like ipad or tablet
        if (width > 768 && width <= 1028) {
            _setSideBarWidth(SideBarWidth.CONDENSED);
            changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.CONDENSED);
        } else if (width > 1028) {
            _setSideBarWidth(SideBarWidth.FIXED);
            changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.FIXED);
        } else if (width < 768) {
            _setSideBarWidth(SideBarWidth.FIXED);
            changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.FIXED);
        }
        setValue(LocalStorageValues.LAYOUT_SIDEBAR_WIDTH, sideBarWidth);
    }, [width]);
    useEffect(() => {
        if (sideBarWidth === SideBarWidth.CONDENSED) {
            if (width < 768) {
                changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.FIXED);
            } else {
                changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.CONDENSED);
            }
        } else {
            changeBodyAttribute('data-leftbar-compact-mode', SideBarWidth.FIXED);
        }
        window.addEventListener('resize', updateDimensions);

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, [updateDimensions]);
    useEffect(() => {
    }, [updateDimensions]);
    return (
        <>
            <div className="wrapper">
                <Suspense fallback={loading()}>
                    <LeftSidebar isAdmin={isAdmin} isAuthorised={isAuthorised} isCondensed={false} isLight={false} hideUserProfile={true} />
                </Suspense>
                <div className="content-page">
                    <div className="content">
                        <Suspense fallback={loading()}>
                            
                            <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
                            <Container fluid>
                                <Suspense fallback={loading()}>{children}</Suspense>
                            </Container>
                        </Suspense>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>
            </div>

            {/* <Suspense fallback={loading()}>
                <RightSidebar>
                    <ThemeCustomizer />
                </RightSidebar>
            </Suspense> */}
        </>
    );
};
export default VerticalLayout;
