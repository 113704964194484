import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { type SubmitHandler, useForm } from 'react-hook-form';
import React, { Dispatch, SetStateAction, useState } from 'react';
import Div from 'components/Div';
import FormInput from 'components/FormInput';
import type { IUserFilters} from '../types';
import { StatusCodes } from '../types/StatusCodes';

type UserFiltersProps = {
    hide: () => void;
    filters: IUserFilters;
    extra: any,
    setFilters: Dispatch<SetStateAction<IUserFilters>>;
};
    const emptyFilters: IUserFilters = {
        search: '',
        emailAddress: '',
        firstName: '',
        lastName: '',
        statusCode: '',
        role: ''
    };

const UserFilters = ({ hide, filters, setFilters, extra }: UserFiltersProps) => {
    //const { data, isLoading, error } = getCompanyFilters();
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm <IUserFilters> ({
        defaultValues: { ...filters }
    });

    const [RoleOptions, setRoleOptions] = useState([{ value: '', text: 'Both' }, { value: 'Admin', text: 'Admin' }, { value: 'Member', text: 'Member' }]);
    
    const statusCodesOptionsList = [{ value: '', text: 'Both' }];
    Object.keys(StatusCodes).forEach( code => statusCodesOptionsList.push({ value: code, text: code }));
    const [statusCodeOptions, setStatusCodeOptions] = useState(statusCodesOptionsList);

    const onSubmit: SubmitHandler<IUserFilters> = async (data) => {
        setFilters(data);
        hide();
        await extra?.onApply(data);
    };
    
    //if (isLoading) {
    //    return (
    //        <Div className="text-center">
    //            <LoadingIndicator />
    //        </Div>
    //    );
    //}
  
    return (
        <Div className="p-2">
            <Form onSubmit={handleSubmit(onSubmit)}>
                {/* <FormInput
                    type="input"
                    label="search"
                    placeholder="search"
                    name="search"
                    register={register}
                    errors={errors}
                    containerClass={'mb-3'}
                /> */}
                <FormInput
                    type="input"
                    label="Email Address"
                    placeholder="Email Address"
                    name="emailAddress"
                    register={register}
                    errors={errors}
                    containerClass={'mb-3'}
                />
                <FormInput
                    type="input"
                    label="Fist Name"
                    placeholder="First Name"
                    name="firstName"
                    register={register}
                    errors={errors}
                    containerClass={'mb-3'}
                />
                <FormInput
                    type="input"
                    label="Last Name"
                    placeholder="Last Name"
                    name="lastName"
                    register={register}
                    errors={errors}
                    containerClass={'mb-3'}
                />
                <FormInput
                    type="select"
                    label="Role"
                    name="role"
                    register={register}
                    options={RoleOptions}
                    errors={errors}
                    containerClass={'mb-3'}
                />
                <FormInput
                    type="select"
                    label="Status"
                    name="statusCode"
                    register={register}
                    options={statusCodeOptions}
                    errors={errors}
                    containerClass={'mb-3'}
                />
                <Div>
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => {
                            setFilters(emptyFilters);
                            reset(emptyFilters);
                            //hide();
                            extra?.onApply(emptyFilters);
                        }}
                    >
                        Reset
                    </Button>
                    <Button className="float-end" type="submit" variant="primary"> Apply </Button>
                </Div>
            </Form>
        </Div>
    )

};

export { UserFilters, emptyFilters };

