import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  OverlayTrigger,
  Button,
  Tooltip,
} from "react-bootstrap";
import { DEV_URL, rget, rpost } from "../../utils/requests";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import { CompanyDetailsV2 } from "./companyDetailsV2";
import Div from "../../components/Div";
import PageTitle from "../../components/PageTitle/index";
import CustomerDetailsHeader from "./Header";

const CompaniesDetailsDataNew = () => {
  const [wasFollowClicked, setWasFollowClicked] = useState(false); // just to check changes in flips
  const [companyLicenseData, setCompanyLicenseData] = useState([]);
  const [companyContactData, setCompanyContactData] = useState([]);
  const [companyAddressData, setCompanyAddressData] = useState([]);
  const [loadingCompanyDetailsData, setLoadingCompanyDetailsData] = useState(false);
  const [companyDetails, setCompanyDetails] = useState();
  let { companyId } = useParams();
 const [isSubscribe,setIsSubscribe] = useState(false);
  
  useEffect(async () => {
    setLoadingCompanyDetailsData(true);
    const _companyDetails = await rget(`${DEV_URL}/api/company/${companyId.toUpperCase()}`);

    setIsSubscribe(_companyDetails.data.isSubscribe);
    setCompanyDetails(_companyDetails.data);
    setLoadingCompanyDetailsData(false);
    return () => {
      setCompanyLicenseData();
      setCompanyContactData();
      setCompanyAddressData();
    };
  }, [isSubscribe]);
  return (
    <>
      <CustomerDetailsHeader company={{...companyDetails}} setCompany={setCompanyDetails} />
      <CompanyDetailsV2 companyLicenseData = {companyLicenseData} companyContactData={companyContactData} companyAddressData={companyAddressData} companyId={companyId} subscribed={[isSubscribe,setIsSubscribe]}/>
    </>
  );
};

export default CompaniesDetailsDataNew;
