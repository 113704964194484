import { useNavigate } from 'react-router-dom';

import DeleteButton from 'components/DeleteButton';
import Div from 'components/Div';
import Text from 'components/Text';
import { DEV_URL, rpost, rdelete } from "../../../utils/requests";
import { useHistory, useLocation } from "react-router-dom";
import useShowSnackbar from 'hooks/useShowSnackbar';
import { useAuth } from 'providers/CustomAuthProvider';

const DangerZone = ({user}) => {
    const showSnackbar = useShowSnackbar();
    const history = useHistory();
    const location = useLocation();
    const {clientId} = useAuth();
    return (
        <>
            <Text variant="h4" className="text-danger">
                Danger Zone
            </Text>
            <Div className="alert alert-danger d-flex align-items-center justify-content-between">
                <Text variant="plain">Remove user from the system.</Text>
                <DeleteButton
                    message={<>Are you sure you want to delete user <b>{user?.emailAddress}</b></>}
                    onDelete={ async () => {
                        const queryParams = `userId=${user?.id}&secureId=${user?.secureId}`;
                          const response = await rdelete(
                              `${DEV_URL}/api/client/${clientId}/user?${queryParams}`
                          );
                          showSnackbar(
                            response?.data || 'something went wrong!',
                            response?.data && (response?.status == 200) ? 'success' : 'error'
                        )
                        history.push('/settings/users');
                    } }
                    confirmLabel='OK'
                    showCancelButton={true}
                />
            </Div>
        </>
    );
};

export default DangerZone;
