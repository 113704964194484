import { Card, Row, Col, Modal, Button } from "react-bootstrap";
import { useEffect } from "react";
import Div from "components/Div";
import { DEV_URL, rpost } from "../../utils/requests";
import React, { useState } from "react";
import { useHistory ,useLocation } from "react-router-dom";
import Text from "components/Text";
import { NotificationsData } from "./notificationContents/notificationData";
import { useApiFilters } from "../../hooks/useApiFilters";
import PageTitle from "../../components/PageTitle/index";



const ManageNotifications = ({companyId }) => {
        const location = useLocation();
        const history = useHistory();
        const queryParams = new URLSearchParams(location.search);
    const [notificationData, setNotificationData] = useState();
    const [companyDataPage, setCompanyDataPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(false);
    const [noDataMessage, setNoDataMessage] = useState('No Data Found');
    const [pageSizes, setPageSizes] = useState([]);
    const [companyDataPerPage, setCompanyDataPerPage] = useState(50);
    const [show,setShow] = useState(false);
    const [message,setMessage] = useState('');
    const handleClose =()=>{
        setShow(false);
    }
    const onViewMessage = async (messageContent,id)=>{
        await markById(id,true);
        setMessage(messageContent);
        setShow(true);
    }
    const {
        filters,
        orderBy,
        ascendingOrder,
        limit,
        page,
        search,
        setPage,
        setSearch,
        hook,
        setFilters
    } = useApiFilters({
        page: 0, limit: 50, filters: {
            search : '',
            from : '',
            to :''
        }
    });
    const paginationComponentOptions = {
        rowsPerPageText: "Rows per Page",
        rangeSeparatorText: "of",
        selectAllRowsItem: true,
        selectAllRowsItemText: "All",
    };
    const markById = async (id,isRead)=> {
        var payload = {id,isRead,isAll:false };
        const response = await rpost(`${DEV_URL}/api/Notification/Mark`,payload);
        if(response?.data!=null){
            if(response?.data==true){
                const notificationsResponse = await rpost(
                    `${DEV_URL}/api/Notification/GetAll`,
                    { PageNumber: page+1, PageSize: limit, SearchTerm: search?.trim(), orderBy: orderBy === undefined ? null : orderBy, ascendingOrder }
                );
                if (notificationsResponse !== 'Data not found') {
                    setNotificationData(notificationsResponse.data.records);
                    setTotalRecords(notificationsResponse.data.total);
                    history.replace(window.location.pathname);
                }
                return true;
            }
        }
        return false;
    }
const onSearchData =async (data)=>{
    setLoading(true);
            
            const notificationsResponse = await rpost(
                `${DEV_URL}/api/Notification/GetAll`,
                { PageNumber: 1, PageSize: limit, SearchTerm: data.search?.trim(), orderBy: orderBy === undefined ? null : orderBy, ascendingOrder,from:data.from,to:data.to }
            );
            if (notificationsResponse !== 'Data not found') {
                setNotificationData(notificationsResponse.data.records);
                setTotalRecords(notificationsResponse.data.total);
                history.replace(window.location.pathname);
            }
            setLoading(false);
}

    // const allread = async ()=>{
    //     var payload = {id:null,isRead:true,isAll:true };
    //             const response = await rpost(`${DEV_URL}/api/Notification/Mark`,payload);
    //             if(response?.data!=null){
    //                 if(response?.data==true){
    //                     if(window.location.href.includes('/notifications')){
    //                         history.replace(window.location.pathname);
    //                     }
    //                 }
    //             }
    // }
        const removeById = async (id)=>{
            var payload = {id,isAll:false };
            const response = await rpost(`${DEV_URL}/api/Notification/Remove`,payload);
            if(response?.data!=null){
                if(response?.data==true){
                    return true;
                }
            }
            return false;
        }
    useEffect(() => {
        const fetcher = async () => {
            if(queryParams.has('ReadAll')){
                history.replace(window.location.pathname);
            } else{
                setLoading(true);
            
            const notificationsResponse = await rpost(
                `${DEV_URL}/api/Notification/GetAll`,
                { PageNumber: page+1, PageSize: limit, SearchTerm: search?.trim(), orderBy: orderBy === undefined ? null : orderBy, ascendingOrder }
            );
            if (notificationsResponse !== 'Data not found') {
                setNotificationData(notificationsResponse.data.records);
                setTotalRecords(notificationsResponse.data.total);
            }
            setLoading(false);
            }       
            
        }
        fetcher();
    }, [limit, page, search, orderBy, ascendingOrder,location.search])
    
    return (
       <> <Modal
        show={show} onHide={handleClose}
    >
        <Modal.Header closeButton>
            <Modal.Title>Message Content</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <h5 className="text-center">{message}</h5>
        </Modal.Body>

        <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>Close</Button>
        </Modal.Footer>
    </Modal>
    <Div className="mt-3">
        <Row>
            <Col lg="12">
                <Card>
                    <Card.Header>
                        <Div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex">
                                <PageTitle title={`Notifications`} />
                                {totalRecords!==0 && <Text variant="subtitle" className="ms-2" style={{ marginTop: "28px" }}>{ totalRecords}</Text>}
                            </div>
                        </Div>
                    </Card.Header>
                    <Card.Body>
                        <NotificationsData onViewMessage={onViewMessage} total={totalRecords} markById={markById} removeById={removeById} onSearch={onSearchData}
                            hook={hook} setNotificationsList={setNotificationData} loading={loading} noDataMessage={noDataMessage} pageSizes={pageSizes} companyId={companyId} notifications={notificationData}></NotificationsData>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </Div></>);
};

export default ManageNotifications;