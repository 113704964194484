import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import LoadingButton from 'components/LoadingButton';
import { useToggle } from 'hooks';
import DeleteIcon from 'components/icons/Delete';

type DeleteButtonProps = {
    title?: string;
    message?: string | React.ReactFragment;
    onDelete: () => Promise<void>;
    variant?: 'button' | 'icon' | 'iconComponent' | 'dropdownItem';
    disabled?: boolean;
    label?: string | React.ReactFragment;
    confirmLabel?: string;
    showCancelButton?: boolean;
};

const DeleteButton = ({
    title,
    message,
    onDelete,
    variant = 'button',
    disabled,
    label: text = 'Delete',
    confirmLabel = 'Ok',
    showCancelButton = false
}: DeleteButtonProps) => {
    const { isOpen, show, hide } = useToggle();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleDelete = async () => {
        setIsLoading(true);
        await onDelete();
        setIsLoading(false);
        hide();
    };
    return (
        <>
            {variant === 'iconComponent' ? (
                <DeleteIcon
                    role="button"
                    style={{ fontSize: 18, marginLeft: 6 }}
                    onClick={show}
                />
            ) : variant === 'icon' ? (
                <></>
                // TODO
                // <FontAwesomeIcon
                //     icon={faTrashAlt}
                //     role="button"
                //     style={{ fontSize: 18 }}
                //     onClick={show}
                // />
            ) : /*  <i
                    className="mdi mdi-trash-can-outline text-danger ms-1"
                    role="button"
                    style={{ fontSize: 18 }}
                    onClick={show}
                ></i> */
            variant === 'dropdownItem' ? (
                <DropdownItem onClick={show} disabled={disabled}>
                    {text}
                </DropdownItem>
            ) : (
                <Button variant="danger" onClick={show} disabled={disabled}>
                    {text}
                </Button>
            )}
            <Modal
                show={isOpen}
                onHide={() => {
                    hide();
                }}
                size={'lg'}
                scrollable={true}
            >
                <Modal.Header onHide={hide} closeButton>
                    <h4 className="modal-title">{title || 'Are you sure?'}</h4>
                </Modal.Header>
                <Modal.Body>
                    {message || 'Are you sure you want to delete?'}
                </Modal.Body>
                <Modal.Footer>
                    {
                        showCancelButton && 
                        <Button variant="light" onClick={hide}>
                        Cancel
                        </Button>
                    }
                    <LoadingButton
                        variant="danger"
                        isLoading={isLoading}
                        onClick={handleDelete}
                    >
                        {confirmLabel}
                    </LoadingButton>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default DeleteButton;
