import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { DEV_URL, rget } from "../../../utils/requests";
import Div from 'components/Div';
import { useToggle } from 'hooks';
import UserFormModal from '../UserFormModal';
import TextLine from 'components/TextLine';
import UserOverviewHeader from './Header';
import DangerZone from './DangerZone';
import { useAuth } from '../../../providers/CustomAuthProvider';

export default function UserOverview() {
    // const { customer } = useCustomer();
    const { isOpen, show, hide } = useToggle();

    let { userId } = useParams();
    const {clientId} = useAuth();

    const [loadingUserData, setLoadingUserData] = useState(false);
    const [userData, setUserData] = useState({});
    const fetchdata = async () => {
        setLoadingUserData(true);
  
        const queryParams = {
          userId: userId
        };
        const userDataResponse = await rget(
            `${DEV_URL}/api/client/${clientId}/users`,
            { params: queryParams }
        );  
        const userData = userDataResponse?.data?.users.filter(user => user.id == userId);
        setUserData(userData[0]);
    };
    useEffect(async () => {
        await fetchdata();
      }, []);

    return (
        <>
            <UserOverviewHeader user={userData}/>
            <Card className="mt-2">
                <Card.Body>
                    <Div className="d-flex align-items-center justify-content-between">
                        <Card.Title className="mb-4">
                            <h3>User Details</h3>
                        </Card.Title>
                        <Col sm={3}>
                            <Div className="text-sm-end">
                                <Button
                                    variant="success"
                                    className="mb-2 me-1"
                                    size="sm"
                                    onClick={show}
                                >
                                    Edit
                                </Button>
                            </Div>
                        </Col>
                    </Div>
                    <Row>
                        <Col xs={4}>
                            <TextLine
                                primary="Email Address:"
                                secondary={userData?.emailAddress}
                            />   
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="First Name:"
                                secondary={userData?.firstName}
                            />
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="Last Name:"
                                secondary={userData?.lastName}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <TextLine
                                primary="Phone:"
                                secondary={userData?.phoneNumber}
                            />   
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="Timezone:"
                                secondary={userData?.timeZone}
                            />   
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="Address Line 1:"
                                secondary={userData?.address1}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <TextLine
                                primary="Address Line 2:"
                                secondary={userData?.address2}
                            />
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="City:"
                                secondary={userData?.city}
                            />
                        </Col>
                        <Col xs={4}>
                            <TextLine
                                primary="State:"
                                secondary={userData?.stateCode}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4}>
                            <TextLine
                                primary="Zip:"
                                secondary={userData?.zipCode}
                            />
                        </Col>
                    </Row>
                    {/* <Row>
                        <Col xs={3}>
                            <EditableTextLine
                                entity={customer}
                                primary="Website"
                                field="websiteUrl"
                                type="text"
                                mutate={mutate}
                                isLoading={isLoading}
                                successMessage={customer_messages.EDIT_SUCCESS}
                                errorMessage={customer_messages.EDIT_ERROR}
                            />
                        </Col>
                        <Col xs={3}>
                            <EditableTextLine
                                entity={customer}
                                primary="Parent Customer"
                                field="parentCustomer"
                                secondary={customer?.parentCustomer?.name || ''}
                                type="asyncTypeahead"
                                mutate={mutate}
                                isLoading={isLoading}
                                options={customers}
                                isLoadingData={isLoadingCustomers}
                                onSearch={setParentCustomersKey}
                                enableEditing={!errorCustomers}
                                successMessage={customer_messages.EDIT_SUCCESS}
                                errorMessage={customer_messages.EDIT_ERROR}
                            />
                        </Col>
                        <Col xs={3}>
                            <EditableTextLine
                                entity={customer}
                                primary="DUNS"
                                field="dUNS"
                                type="text"
                                mutate={mutate}
                                isLoading={isLoading}
                                successMessage={customer_messages.EDIT_SUCCESS}
                                errorMessage={customer_messages.EDIT_ERROR}
                            />
                        </Col>
                        <Col xs={3}>
                            <TextLine
                                primary="CRM Customer ID:"
                                secondary={
                                    customer.crmid ? customer.crmid : '-'
                                }
                            />
                        </Col>
                    </Row> */}
                </Card.Body>
            </Card>
            <DangerZone user={userData} />
            {isOpen && (
                <UserFormModal afterSubmitCallback={fetchdata} key={'userUpdateForm'} isOpen={isOpen} hide={hide} isUpdateMode={true} user={userData} />
            )}
        </>
    );
}
