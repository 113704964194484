import { DEV_URL } from "./requests";
import { useEffect, useState } from "react";
import { HubConnectionBuilder } from "@microsoft/signalr";
import { useAuth } from "providers/CustomAuthProvider";


interface INotificationHubProps {
    children : React.ReactNode;
    onReceived: any
}

const NotificationHub: React.FC<INotificationHubProps> = ({ children,onReceived  }) => {
    const [connection, setConnection] = useState(null);
    const [connected, setConnected] = useState(false);

    const {accessToken} = useAuth();

    useEffect(()=>{
        startConnection()
    },[accessToken])


    const startConnection = async () => {
        const token = accessToken;
        if (token) {
            const hubConnection = new HubConnectionBuilder()
        .withUrl(`${DEV_URL}/notifications-alerts`, {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization token from localStorage
          },
        })
        .build();
            
        hubConnection
        .start()
        .then(() => {
          setConnected(true);
          console.log("SignalR Connected!");
        })
        .catch((err) => {
          console.error("Error while connecting to SignalR: ", err);
        });

        hubConnection.on("NotificationReceive", (response) => {
            onReceived(response);
          });
          setConnection(hubConnection);
        }

    };

    const stopConnection = () => {
        if (connection) {
          connection.stop().then(() => {
            setConnected(false);
            console.log("SignalR Disconnected!");
          });
        }
      };

      return (<>{children}</>)
}

export default NotificationHub