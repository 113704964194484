const timezones = [
    "America/Adak",
"America/Anchorage",
"America/Boise",
"America/Chicago",
"America/Denver",
"America/Detroit",
"America/Indiana/Indianapolis",
"America/Indiana/Knox",
"America/Indiana/Marengo",
"America/Indiana/Petersburg",
"America/Indiana/Tell_City",
"America/Indiana/Vevay",
"America/Indiana/Vincennes",
"America/Indiana/Winamac",
"America/Juneau",
"America/Kentucky/Louisville",
"America/Kentucky/Monticello",
"America/Los_Angeles",
"America/Menominee",
"America/Metlakatla",
"America/New_York",
"America/Nome",
"America/North_Dakota/Beulah",
"America/North_Dakota/Center",
"America/North_Dakota/New_Salem",
"America/Phoenix",
"America/Puerto_Rico",
"America/Sitka",
"America/St_Thomas",
"America/Yakutat",
"Napakiak",
"Pacific/Chuuk",
"Pacific/Guam",
"Pacific/Honolulu",
"Pacific/Majuro",
"Pacific/Pago_Pago",
"Pacific/Palau",
"Pacific/Saipan"
];

export default timezones;