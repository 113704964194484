import { Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Div from "../../../components/Div";
import PageTitle from "../../../components/PageTitle/index";
import { useEffect, useState } from "react";
import { DEV_URL, rpost } from "../../../utils/requests";
import ThreeDotMenu from '../../../components/ThreeDotMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import Spinner from "../../../components/Spinner";
import { AddressDataTable } from "./ContactInfo/index";
import { sortByProperty, filterRecords } from './../../../helpers/api/utils';
import Text from "components/Text";
import { useAuth } from "providers/CustomAuthProvider";
import ConfirmButton from "../../../components/ConfirmButton"

export const AliasesData = ({ companyId , onSetDisplayName,onRemoveAlias,onSwapParent,priviladge}) => {
    const [addressContactData, setAddressContactData] = useState();
    const [relatedCompaniesData, setRelatedCompaniesData] = useState();
    const [loadingCompanyDetailsData, setLoadingCompanyDetailsData] = useState();

    const { isSuperAdmin } = useAuth();
    const customStyles = {
        header: {
            style: {
                backgroundColor: "#eef2f7",
                fontSize: "11px",
                color: "#474d56",
                minHeight: "28px"
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eef2f7",
                fontSize: "11px",
                color: "#474d56",
                minHeight: "28px"
            },
        },
        headCells: {
            style: {
                '&:not(:last-of-type)': {
                    backgroundColor: "#eef2f7",
                    fontSize: "11px",
                    color: "#474d56",
                    minHeight: "28px"
                },
            },
        },
        rows: {
            style: {
                minHeight: "28px",
                fontSize: "11px"
            }
        }
    }
    const addressColumns = [
        {
            name: "Type",
            selector: (row) => <div dangerouslySetInnerHTML={{ __html: `<span style="font-size:10px" title="${row.aliasType === 'FKA' ? 'For Known As' : 'Doing Business As'}" class="px-2 font-monospace badge bg-${row.aliasType === 'FKA' ? 'warning' : 'info'}">${row.aliasType}</span>` }} />,
            grow: 0,
            sortable: false,
        },
        {
            name: "Alias Name",
            selector: (row) => row.aliasName,
            grow: 1,
            sortable: true,
        }
    ];
    if (isSuperAdmin) {
        addressColumns.push({
            name: "Action",
            selector: (row) => 
                 <ThreeDotMenu className="text-center">
                    <DropdownItem onClick={(e) => {
                        onSetDisplayName(row.id)
                    }}><small>Set Primary</small></DropdownItem>
                    <ConfirmButton message="Are you sure you want to delete this item? This action cannot be undone." confirmLabel="proceed" onConfirm={(e) => {
                        onRemoveAlias(row.id)
                    }} label={<small>Remove</small>} variant="dropdownItem"></ConfirmButton>
                </ThreeDotMenu>
            ,
            grow: 1,
            sortable: false,
        });
    }
    const relatedCompaniesColumns = [
        {
            name: "Legal Name",
            selector: (row) => <Text target="" variant="link" className="link-color" url={((row.id === null || row.id === undefined) ? '' : row.id)}>
                {((row.legalName === null || row.legalName === undefined) ? '' : row.legalName)}
            </Text>,
            grow: 1,
            sortable: true,
        },
        {
            name: "Domain",
            selector: (row) => <Text variant="link" className="link-color" url={((row.emailDomain === null || row.emailDomain === undefined) ? '' : 'http://' + row.emailDomain)}>
                {((row.emailDomain === null || row.emailDomain === undefined) ? '' : row.emailDomain)}
            </Text>,
            grow: 1,
            sortable: false,
        },
        {
            name: "Corporate Type",
            selector: (row) => (row.corporateType === undefined || row.corporateType == null || row.corporateType==='')?'N/A':row.corporateType,
            grow: 1,
            sortable: true,
        },
        {
            name: "Total License",
            selector: (row) => row.licenseCount,
            grow: 1,
            sortable: true,
        },
        {
            name: "Total Contacts",
            selector: (row) => row.contactCount,
            grow: 1,
            sortable: true,
        }
    ]
    if (isSuperAdmin) {
        relatedCompaniesColumns.push({
            name: "Action",
            selector: (row) => 
                 <ThreeDotMenu className="text-center">
                    <ConfirmButton message="Are you sure you want to change parent? This action cannot be undone." confirmLabel="proceed" onConfirm={(e) => {
                        onSwapParent(row.id)
                    }} label={<small>Set Parent</small>} variant="dropdownItem"></ConfirmButton>
                    {/* <br />
                    <DropdownItem onClick={(e) => {
                        onRemoveAlias(row.id)
                    }}>Remove</DropdownItem> */}
                </ThreeDotMenu>
            ,
            grow: 1,
            sortable: false,
        });
    }
    useEffect(async () => {
        setLoadingCompanyDetailsData(true);
        const companyAddressResponse = await rpost(
            `${DEV_URL}/api/Company/GetAlias`,
            {
                PageNumber: 1,
                PageSize: 100,
                SearchTerm: "",
                companyId: companyId,
            }
        );
        setAddressContactData(companyAddressResponse?.data);
        const relatedCompaniesResponse = await rpost(
            `${DEV_URL}/api/Company/GetRelatedCompanies`,
            {
                PageNumber: 1,
                PageSize: 100,
                SearchTerm: "",
                companyId: companyId,
            }
        ); 
        setRelatedCompaniesData(relatedCompaniesResponse.data);
        setLoadingCompanyDetailsData(false);
        return () => {
            setAddressContactData();
        };
        
    }, []);
    return (
        <span>
            <Div className="mt-4">
            <PageTitle title="Company Aliases" ></PageTitle>
            </Div>
            <Card>
                <Card.Body>
                    <Row>
                        <Col sm="12">
                            {/* {loadingCompanyDetailsData && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              )} */}
                            <DataTable
                                columns={addressColumns}
                                data={addressContactData}
                                customStyles={customStyles}
                                className="license-table"
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Div className="mt-4">
            <PageTitle title="Child Companies" ></PageTitle>
            </Div>
            <Card>
                <Card.Body>
                    <Row>
                        <Col sm="12">
                            {/* {loadingCompanyDetailsData && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              )} */}
                            <DataTable
                                columns={relatedCompaniesColumns}
                                data={relatedCompaniesData}
                                customStyles={customStyles}
                                className="license-table"
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </span>
    );
};
