
import { DEV_URL, rpost, rput, rdelete } from "../../../utils/requests";
import { StatusCodes } from '../types/StatusCodes';
import useShowSnackbar from 'hooks/useShowSnackbar';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import ThreeDotMenu from 'components/ThreeDotMenu';
import DeleteButton from "components/DeleteButton";
import { useAuth } from "providers/CustomAuthProvider";

const ActionDropdown = ({userId, statusCode, secureId, onApiCallResponse, emailAddress}) => {
    const showSnackbar = useShowSnackbar();
    const { clientId } = useAuth();
    var BlockUnblockBtn = <DeleteButton
        variant="dropdownItem"
        label={<small>{statusCode =="10800" ? "Block" : "Unblock"}</small>}
        message={<>Are you sure you want to <b>{statusCode =="10800" ? "Block" : "Unblock"}</b> user <b>{emailAddress}</b></>}
        onDelete={ async () => { await StatusUpdateCall() } }
        confirmLabel='OK'
        showCancelButton={true}
    />;
    var ResetPasswordBtn = <DropdownItem onClick={async (e) => { await ResetPasswordCall() } }> <small>Reset Password</small></DropdownItem>;
    var DeleteBtn = <DeleteButton
        variant="dropdownItem"
        label={<small>Delete</small>}
        message={<>Are you sure you want to delete user <b>{emailAddress}</b></>}
        onDelete={ async () => { await deleteUserCall() } }
        confirmLabel='OK'
        showCancelButton={true}
    />;

    const StatusUpdateCall = async () => {
        const updatedStatusCode = StatusCodes.Active == statusCode ? StatusCodes.Blocked: StatusCodes.Active;
        const queryParams = `userId=${userId}&statusCode=${updatedStatusCode}&secureId=${secureId}`;
        const response = await rput(
            `${DEV_URL}/api/client/${clientId}/user/status-update?${queryParams}`, {}
        );
        showSnackbar(
            response?.data || 'something went wrong!',
            response?.data && (response?.status == 200) ? 'success' : 'error'
        )
        onApiCallResponse();
    };

    const ResetPasswordCall = async () => {
        const updatedStatusCode = statusCode =="10800" ? "10900" : "10800";
        const queryParams = `userId=${userId}`;
        const response = await rput(
            `${DEV_URL}/api/client/${clientId}/user/password-reset?${queryParams}`, {}
        );
        showSnackbar(
            response?.data || 'something went wrong!',
            response?.data && (response?.status == 200) ? 'success' : 'error'
        )
        onApiCallResponse();
    };

    const deleteUserCall = async () => {
        const queryParams = `userId=${userId}&secureId=${secureId}`;
        const response = await rdelete(
            `${DEV_URL}/api/client/${clientId}/user?${queryParams}`
        );
        showSnackbar(
            response?.data || 'something went wrong!',
            response?.data && (response?.status == 200) ? 'success' : 'error'
        )
        onApiCallResponse();
    };

    return <ThreeDotMenu className="text-center">
        {BlockUnblockBtn}
        {ResetPasswordBtn}
        {DeleteBtn}
    </ThreeDotMenu>
};

export default ActionDropdown;
