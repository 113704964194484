import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';

export const deleteIcon: IconDefinition = {
    prefix: 'fas', // Leave as it is to avoid conflict with font awesome component
    iconName: '0', // Leave as it is to avoid conflict with font awesome component
    icon: [
        448, // width
        512, // height,
        [''], // Leave as it is to avoid conflict with font awesome component
        '', // Leave as it is to avoid conflict with font awesome component
        'M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z'
    ]
};

const DeleteIcon = (props: Omit<FontAwesomeIconProps, 'icon'>) => {
    return <FontAwesomeIcon icon={deleteIcon} {...props} />;
};

export default DeleteIcon;
