import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';

export const arrowRightIcon: IconDefinition = {
    prefix: 'fas', // Leave as it is to avoid conflict with font awesome component
    iconName: '0', // Leave as it is to avoid conflict with font awesome component
    icon: [
        512, // width
        512, // height,
        [''], // Leave as it is to avoid conflict with font awesome component
        '', // Leave as it is to avoid conflict with font awesome component
        'M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z'
    ]
};

const ArrowRightIcon = (props: Omit<FontAwesomeIconProps, 'icon'>) => {
    return <FontAwesomeIcon icon={arrowRightIcon} {...props} />;
};

export default ArrowRightIcon;