import { useMemo, useEffect } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import TabsContainer, { Tab } from 'components/TabsContainer';
import SubscribedCompanies from './SubscribedCompanies';
import UserList from '../users/UserList';
import SettingsIcon from 'components/icons/Settings';
import UsersIcon from 'components/icons/Users';

const Settings = () => {

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        // by default navigate to users subtab
        if (location.pathname === '/settings') {
            history.push('/settings/users');
        } 
    }, []);

    const tabs = [
            {
                title: 'General',
                iconComponent: <SettingsIcon />,
                url: '/settings/general',
                content: (<><br /><br /><center><h3>Coming Soon!</h3></center></>)
            },
            {
                title: 'Users',
                iconComponent: < UsersIcon />,
                url: '/settings/users',
                content: <UserList />
            },
            {
                title: 'Subscriptions',
                iconComponent:  <i className="dripicons-bell noti-icon"></i>,
                url: '/settings/subscriptions',
                content: <SubscribedCompanies />
            }
        ];

    // const tabContent = (
    //     <Switch>
    //         <PrivateRoute layout={EmptyLayout} path="/settings/general" exact component={comingSoonDiv}/>
    //         <PrivateRoute layout={EmptyLayout} path="/settings/users" AdminAccessOnly exact component={UserList}/>
    //         <PrivateRoute layout={EmptyLayout} path="/settings/users/:userId/overview" AdminAccessOnly exact component={UserOverview}/>
    //         {/* <PrivateRoute layout={EmptyLayout} path="/settings/notifications" exact component={SubscribedCompanies}/> */}
    //     </Switch>
    // );

    return (
        <>
            <h2>Settings</h2>
            <TabsContainer tabs={tabs}></TabsContainer>
        </>
    );
};

export default Settings;
