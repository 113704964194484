import { useSnackbar, type VariantType } from 'notistack';
import CloseButton from 'react-bootstrap/CloseButton';

export default function useShowSnackbar() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return (
        message: string,
        variant?: VariantType,
        style?: any,
        autoHideDuration = 10000
    ) =>
        enqueueSnackbar(message, {
            variant,
            autoHideDuration,
            style,
            action: (snackbarId) => (
                <CloseButton
                    aria-label="close"
                    variant="white"
                    onClick={() => closeSnackbar(snackbarId)}
                />
            )
        });
}
