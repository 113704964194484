import classNames from 'classnames';
import { useEffect, useState } from 'react';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
    SortingState,
    Row,
    RowSelectionState
} from '@tanstack/react-table';
import { ColumnDef } from '@tanstack/table-core';
import RowBS from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import SearchTable from './SearchTable';
import Pagination from './Pagination';
import ColumnToggler from './ColumnToggler';
import DateColumn from './DateColumn';
import LinkColumn from './LinkColumn';
import AppliedFilters from './AppliedFilters';
import { convertHiddenColumnsArray } from './utils';
import SortingIcon from './SortingIcon';

import { getTablePaginationSizes } from 'utils/get-table-pagination-sizes';
import { noop } from 'utils/index';
import { ApiFilters } from 'types/api-inputs.type';
import LoadingIndicator from 'components/LoadingIndicator';
import Drawer, { DrawerContentProps } from 'components/Drawer';
import FiltersIcon from 'components/icons/Filters';
import IndeterminateCheckbox from 'components/IndeterminateCheckbox';
import Div from 'components/Div';

type TableProps<TableValues> = {
    columns: ColumnDef<TableValues, any>[];
    data: TableValues[];
    loading?: boolean;
    enableSearch?: boolean;
    enablePagination?: boolean;
    enableHideColumns?: boolean;
    enableSorting?: boolean;
    enableFilters?: boolean;
    enableExport?: boolean;
    enableSelection?: boolean;
    enablePaginateAll?: boolean;
    total?: number;
    pageSizes?: number[];
    hook?: () => ApiFilters<TableValues>;
    filters?: (
        props: DrawerContentProps &
            Pick<ApiFilters<TableValues>, 'filters' | 'setFilters'|'extra'>
    ) => JSX.Element;
    hiddenColumns?: string[];
    onExport?: () => void;
    rowClassnames?: (row: Row<TableValues>) => string;
    onRowSelected?: (selectedRows: TableValues[]) => void;
    defaultAllSelected?: boolean;
    noDataMessage?: string;
    searchPlaceholder?: string;
    extra?: any;
};

function noopHook<TableValues>(): ApiFilters<TableValues> {
    return {
        ascendingOrder: false,
        limit: 10,
        orderBy: undefined,
        page: 0,
        search: '',
        setAscendingOrder: noop,
        setLimit: noop,
        setOrderBy: noop,
        setPage: noop,
        setSearch: noop,
        filters: null,
        setFilters: noop,
        extra:null
    };
}

const Table = <TableValues extends object = Record<string, never>>({
    columns,
    data = [],
    searchPlaceholder = undefined,
    loading = false,
    enableSearch = true,
    enablePagination = true,
    enableHideColumns = true,
    enableSorting = true,
    enableFilters = true,
    enableSelection = false,
    enablePaginateAll = true,
    pageSizes = [],
    hook = noopHook,
    filters: Filters = undefined,
    hiddenColumns = [],
    onExport = noop,
    enableExport = false,
    rowClassnames,
    onRowSelected,
    defaultAllSelected = false,
    total = 0,
    extra = null,
    noDataMessage = 'No data found'
}: TableProps<TableValues>) => {
    const {
        limit,
        setLimit,
        page,
        setPage,
        search,
        setSearch,
        orderBy,
        setOrderBy,
        ascendingOrder,
        setAscendingOrder,
        filters,
        setFilters
    } = hook();
    const [sorting, setSorting] = useState<SortingState>(
        orderBy ? [{ id: String(orderBy), desc: !ascendingOrder }] : []
    );
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const onClose = (data) => {
        extra?.onApply(data);
    }
    const [isFilterDrawerOpen, setIsFilterDrawerOpen] =
        useState<boolean>(false);
    const openFilterDrawer = () => setIsFilterDrawerOpen(true);
    const closeFilterDrawer = () => setIsFilterDrawerOpen(false);

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        state: {
            sorting,
            rowSelection
        },
        initialState: {
            columnVisibility: convertHiddenColumnsArray(hiddenColumns)
        },
        onSortingChange: setSorting,
        onRowSelectionChange: setRowSelection,
        manualSorting: true,
        manualPagination: true
    });

    const sizes = getTablePaginationSizes(
        total,
        enablePaginateAll,
        ...pageSizes
    );

    // Update orderBy on sorting change
    useEffect(() => {
        setOrderBy(sorting[0]?.id as keyof TableValues);
        setAscendingOrder(sorting.length > 0 ? !sorting[0]?.desc : false);
    }, [sorting]);

    useEffect(() => {
        if (enableSelection && onRowSelected) {
            onRowSelected(
                table.getSelectedRowModel().rows.map((row) => row.original)
            );
        }
    }, [rowSelection, enableSelection]);

    useEffect(() => {
        if (enableSelection && defaultAllSelected && data?.length > 0) {
            table.toggleAllRowsSelected(true);
        }
    }, [data, enableSelection, defaultAllSelected]);

    return (
        <>
            <RowBS className="mb-1">
                <Col xl={5} xxl={3}>
                    {enableSearch && (
                        <SearchTable
                            search={search}
                            setSearch={setSearch}
                            placeholder={searchPlaceholder}
                            buttonDisabled={loading}
                            setPage={setPage}
                        />
                    )}
                </Col>
                <Col className="d-flex justify-content-end align-items-center">
                    {enableExport && (
                        <Button variant="secondary mx-1" onClick={onExport}>
                            Export
                        </Button>
                    )}

                    {enableFilters && Boolean(Filters) && (
                        <>
                            <Button
                                variant="primary"
                                className="mx-1"
                                onClick={openFilterDrawer}
                            >
                                <FiltersIcon />
                            </Button>
                            {isFilterDrawerOpen && (
                                <Drawer
                                    isOpen={isFilterDrawerOpen}
                                    hide={closeFilterDrawer}
                                    content={
                                        <Filters
                                            hide={closeFilterDrawer}
                                            filters={filters}
                                            setFilters={setFilters}
                                            extra={extra}
                                        />
                                    }
                                />
                            )}
                        </>
                    )}
                    {enableHideColumns && (
                        <ColumnToggler columns={table.getAllColumns()} />
                    )}
                </Col>
            </RowBS>
            {enableFilters && <AppliedFilters hook={hook} filterClose={onClose} />}
            <div className="table-responsive fs-11">
                <table
                    className={classNames('table table-centered react-table')}
                >
                    <thead className="table-light">
                        {table.getHeaderGroups().map((headerGroup, index) => (
                            <tr key={index}>
                                {enableSelection && (
                                    <th>
                                        <IndeterminateCheckbox
                                            {...{
                                                disabled: loading,
                                                checked:
                                                    table.getIsAllPageRowsSelected(),
                                                indeterminate:
                                                    table.getIsSomePageRowsSelected(),
                                                onChange:
                                                    table.getToggleAllPageRowsSelectedHandler()
                                            }}
                                        />
                                    </th>
                                )}
                                {headerGroup.headers.map((header, index) => {
                                    return (
                                        <th key={`${header.id}-${index}`}>
                                            <Div
                                                style={{
                                                    whiteSpace: 'normal'
                                                }}
                                                className={classNames(
                                                    'd-flex',
                                                    'justify-content-between',
                                                    'align-items-center',
                                                    {
                                                        'cursor-pointer':
                                                            enableSorting &&
                                                            header.column.getCanSort(),
                                                        'select-none':
                                                            enableSorting &&
                                                            header.column.getCanSort()
                                                    }
                                                )}
                                                onClick={header.column.getToggleSortingHandler()}
                                            >
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                          header.column
                                                              .columnDef.header,
                                                          header.getContext()
                                                      )}
                                                {enableSorting &&
                                                    header.column.getCanSort() && (
                                                        <SortingIcon
                                                            isSorted={header.column.getIsSorted()}
                                                        />
                                                    )}
                                            </Div>
                                        </th>
                                    );
                                })}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td
                                    colSpan={
                                        table.getHeaderGroups()[0].headers
                                            .length + (enableSelection ? 1 : 0)
                                    }
                                    className="text-center py-1"
                                >
                                    <LoadingIndicator />
                                </td>
                            </tr>
                        ) : table.getRowModel().rows.length > 0 ? (
                            table.getRowModel().rows.map((row, index) => (
                                <tr
                                    key={`${row.id}-${index}`}
                                    className={
                                        rowClassnames && rowClassnames(row)
                                    }
                                >
                                    {enableSelection && (
                                        <td>
                                            <IndeterminateCheckbox
                                                {...{
                                                    checked:
                                                        row.getIsSelected(),
                                                    disabled:
                                                        !row.getCanSelect(),
                                                    indeterminate:
                                                        row.getIsSomeSelected(),
                                                    onChange:
                                                        row.getToggleSelectedHandler()
                                                }}
                                            />
                                        </td>
                                    )}
                                    {row
                                        .getVisibleCells()
                                        .map((cell, cellIndex) => (
                                            <td key={`${cell.id}-${cellIndex}`}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </td>
                                        ))}
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td
                                    className="text-center"
                                    colSpan={
                                        table.getHeaderGroups()[0].headers
                                            .length + (enableSelection ? 1 : 0)
                                    }
                                >
                                    {noDataMessage}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {enablePagination && (
                <Pagination
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    setLimit={setLimit}
                    total={total}
                    sizePerPageList={sizes}
                    disabled={loading}
                />
            )}
        </>
    );
};

export { DateColumn, LinkColumn };

export default Table;
