import { Card, Col, Nav, Row, Tab, Modal, Button } from "react-bootstrap";
import { companyTabContents, mockDataAliasesInfo, mockDataContactInfo, mockDataStateServices } from "../constants";
import { DEV_URL, rpost,rget } from "../../../utils/requests";
import { useEffect, useState } from "react";
import { LicenseAndCaseHistories } from "./LicenseAndCaseHistories";
import { ContactInfo } from "./ContactInfo";
import { AliasesData } from "./AliasesService";
export const CompanyDetailsV2 = ({companyLicenseData, companyContactData, companyAddressData, companyId,subscribed}) => {
    //const [activeTab, setActiveTab] = useState(companyTabContents[0].title);
    //const [data, setData] = useState();
    const [show, setShow] = useState(false);
    const [screenshotUrl, setScreenshotUrl] = useState('');
    const handleShow = (url) => {
        setScreenshotUrl(url);
        setShow(true);
    }
    
    const changeDisplayName = async (AliasId) => {
        if(window.confirm("Are you sure you want to change name? This action cannot be undone.")){
            const setDisplayNameResponse = await rpost(
                `${DEV_URL}/api/company/ChangeDisplayName`,
                { CompanyId: companyId, AliasId: AliasId }
            );
            window.location.reload();
        }

        
    }
    const swapParentChild = async (ChildId) => {
        await rpost(
                `${DEV_URL}/api/company/SwapParentChaild`,
                { CompanyId: companyId, AliasId: ChildId }
            );
            window.location.reload();
        
    }
    const removeAlias = async (AliasId) => {
            await rget(
                `${DEV_URL}/api/company/RemoveAlias/${AliasId}`
            );
            window.location.reload();
    }
    const handleClose = () => setShow(false);
    //const handleActiveTab = (tab) => {
    //    setActiveTab(tab);
    //}

    //const dataObjects = {
    //    "Licenses": companyLicenseData,
    //    "Contacts": mockDataContactInfo,
    //    "Aliases": mockDataAliasesInfo
    //  };

        // API will be called here
    companyLicenseData.screenShotEvent = handleShow;
    useEffect(()=>{
        //setData(dataObjects[activeTab]);
    }, [companyLicenseData, companyContactData, companyAddressData, mockDataAliasesInfo])
    return (
        <>
            <Modal dialogClassName="modal-90w"
                show={show} onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Preview Image</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <img className="w-100" alt="Screen" src={screenshotUrl} />
                </Modal.Body>

                <Modal.Footer>
                        <Button variant="primary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <div>
                <LicenseAndCaseHistories fetchedData={companyLicenseData} companyId={companyId} subscribed={subscribed}></LicenseAndCaseHistories>
                <ContactInfo fetchedData={mockDataContactInfo} companyId={companyId}></ContactInfo>
                <AliasesData fetchedData={mockDataAliasesInfo} companyId={companyId} onSetDisplayName={changeDisplayName} onRemoveAlias={removeAlias} onSwapParent={swapParentChild} priviladge={{ superUser: true }}></AliasesData>

            {/*<Tab.Container activeKey={activeTab} onSelect={handleActiveTab}>*/}
            {/*    <Nav variant='tabs' className='nav-bordered' as='ul'>*/}
            {/*        {companyTabContents.map((tab, index) => {*/}
            {/*            return(*/}
            {/*            <Nav.Item key={index} as="li">*/}
            {/*                <Nav.Link href="#" eventKey={tab.title} className={activeTab === tab.title ? "tab-bg" : ""} >*/}
            {/*                    <span className={`d-none d-md-block font-sans-serif ${activeTab === tab.title ? 'fs-active-tab-color' : ''}`}>{tab.title}</span>*/}
            {/*                </Nav.Link>*/}
            {/*            </Nav.Item>*/}
            {/*        )})}*/}
            {/*    </Nav>*/}
            {/*    <Tab.Content>*/}
            {/*        {companyTabContents.map((tab, index)=> {*/}
            {/*            return(*/}
            {/*            <Tab.Pane eventKey={tab.title} id={tab.id} key={index}>*/}
            {/*                {data && <tab.content fetchedData={data} companyId={companyId}/>}*/}
            {/*            </Tab.Pane>*/}
            {/*        )})}*/}
            {/*    </Tab.Content>*/}
            {/*</Tab.Container>*/}
            </div>
        </>
    );
}
