import { Card, Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Div from "../../../components/Div";
import PageTitle from "../../../components/PageTitle/index";
import { useEffect, useState } from "react";
import { DEV_URL, rpost } from "../../../utils/requests";
import Spinner from "../../../components/Spinner";
import { ContactsDataTable, AddressDataTable } from "./ContactInfo/index";
import { sortByProperty, filterRecords } from './../../../helpers/api/utils';
export const ContactInfo = ({ fetchedData, companyId }) => {
    const [companyContactData, setCompanyContactData] = useState();
    const [addressContactData, setAddressContactData] = useState();
  const [loadingCompanyDetailsData, setLoadingCompanyDetailsData] = useState();
  const [companyDataCount, setCompanyDataCount] = useState();
  const [companyDataPage, setCompanyDataPage] = useState(1);
  const [companyDataPerPage, setCompanyDataPerPage] = useState(50);

  const { contact, Addresses } = fetchedData;

  const contactColumns = [
    {
      name: "Full Name",
      selector: (row) => row.contactName || "",
      grow: 1,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row) => row.contactRole,
      grow: 1,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.contactPhone,
      grow: 1,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.contactEmail,
      grow: 1,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.contactAddress,
      grow: 1,
      sortable: true,
    },
  ];

  const addressColumns = [
    {
      name: "Company Name",
      selector: (row) => row.companyName || "",
      grow: 1,
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row.state || "",
      grow: 1,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.contactAddress,
      grow: 1,
      sortable: true,
    },
    {
      name: "Zipcode",
      selector: (row) => row.zipcode || "", 
      grow: 1,
      sortable: true,
    },
  ];

  const paginationComponentOptions = {
    rowsPerPageText: "Rows per Page",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const handleCompanyDataPageChange = (companyDataPage) => {
    setCompanyDataPage(companyDataPage);
  };

  const handleCompanyDataPerRowsChange = (companyDataPerPage) => {
    setCompanyDataPerPage(companyDataPerPage);
  };

  useEffect(async () => {
    setLoadingCompanyDetailsData(true);
      const companyLicensesDataResponse = await rpost(
          `${DEV_URL}/api/contact/getall`,
          {
              PageNumber: companyDataPage,
              PageSize: companyDataPerPage,
              SearchTerm: "",
              companyId: companyId,
          }
      );
      const companyAddressResponse = await rpost(
          `${DEV_URL}/api/Address/getall`,
          {
              PageNumber: companyDataPage,
              PageSize: companyDataPerPage,
              SearchTerm: "",
              companyId: companyId,
          }
      );
      setCompanyContactData(companyLicensesDataResponse.data);
      setAddressContactData(companyAddressResponse?.data);
    setCompanyDataCount(companyLicensesDataResponse.data?.totalRecords);
    setLoadingCompanyDetailsData(false);

    return () => {
      setCompanyContactData();
    };
  }, []);
    const address = addressContactData?.filter(x => (x.contactAddress ?? '').trim() !== '');
    console.log("address", address);
  return (
    <span>
      <Div className="d-flex align-items-center justify-content-between">
        <PageTitle title={"Contacts"} />
      </Div>
      <Card className="">
        <Card.Body>
          <Row>
            <Col sm="12">
              {/* {loadingCompanyDetailsData && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              )}
              {companyContactData?.contact && (
                <DataTable
                  columns={contactColumns}
                  data={companyContactData?.contact}
                />
              )} */}
              {companyContactData?.contact && (
                <ContactsDataTable
                                  contacts={(sortByProperty(filterRecords(companyContactData?.contact, ['contactName', 'contactRole', 'contactEmail','contactPhone']), ['contactName']) )}
                  loading={loadingCompanyDetailsData}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
          </Card>
              <Div className="d-flex align-items-center justify-content-between">
                  <PageTitle title={"Addresses"} />
              </Div>
              <Card>
                  <Card.Body>
                      <Row>
                          <Col sm="12">
                              {/* {loadingCompanyDetailsData && (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner />
                </div>
              )} */}
                          <AddressDataTable addresses={address} loading={loadingCompanyDetailsData} />
                          </Col>
                      </Row>
                  </Card.Body>
              </Card>
      
    </span>
  );
};
