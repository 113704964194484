import { IUserData } from "../types";
import Table, { LinkColumn } from "../../../components/Table/index";
import { ColumnDef } from "@tanstack/table-core";
import ActionDropdown from "./ActionDropdown";
import { Badge } from 'react-bootstrap';
import { StatusCodes } from '../types/StatusCodes';

type UsersDataProps = {
    users?: IUserData[];
    hiddenColumns?: string[];
    enableActions?: boolean;
    loading?: boolean;
    total?: number;
    hook?: any;
    pageNo?: number;
    filters?: any;
    extra?: any;
    onApiCallResponse?: any;
};
const imageerror = (e) => {
    e.target.style.display = "none";
}


export const UserTable = ({
    users,
    loading,
    total,
    hook,
    filters,
    hiddenColumns,
    enableActions = false,
    extra,
    onApiCallResponse = () => {}
}: UsersDataProps) => {
    const columns: ColumnDef<IUserData>[] = [
        {
            header: "Email Address",
            accessorKey: "emailAddress",
            enableSorting: true,
            cell: (props) => {
                return <LinkColumn
                    value={props.row.original.emailAddress}
                    url={`/settings/users/${props?.row?.original?.id}/overview/`}
                />
            },
        },
        {
            header: "First Name",
            accessorKey: "firstName",
            enableSorting: true,
            cell: (props) => {
                return <span>{`${(props.row.original.firstName === null || props.row.original.firstName === undefined) ? '' : props.row.original.firstName}`}</span>
            },
        },
        {
            header: "Last Name",
            accessorKey: "lastName",
            enableSorting: true,
            cell: (props) => {
                return <span>{`${(props.row.original.lastName === null || props.row.original.lastName === undefined) ? '' : props.row.original.lastName}`}</span>
            },
        },
        {
            header: "Role",
            accessorKey: "role",
            enableSorting: false,
            cell: (props) => {
                return <span>{`${(props.row.original.role === null || props.row.original.role === undefined) ? '' : props.row.original.role}`}</span>
            },
        },
        {
            header: "Timezone",
            accessorKey: "timezone",
            enableSorting: false,
            cell: (props) => {
                return <span>{`${(props.row.original.timeZone === null || props.row.original.timeZone === undefined) ? '' : props.row.original.timeZone}`}</span>
            },
        },
        {
            header: "Status",
            accessorKey: "status",
            enableSorting: false,
            cell: (props) => {
                let statusBadge = <>Not Available</>;
                if (props.row.original.statusCode == StatusCodes.Active)
                    statusBadge = <Badge bg="success">Active</Badge>;
                if (props.row.original.statusCode == StatusCodes.Blocked)
                    statusBadge = <Badge bg="danger">Blocked</Badge>;
                return <span>{statusBadge}</span>
            },
        },
        {
            header: 'Action',
            // grow: 0,
            cell: (props) => {
                return <ActionDropdown 
                    userId={props.row.original.id}
                    statusCode={props.row.original.statusCode}
                    secureId={props.row.original.secureId}
                    onApiCallResponse={onApiCallResponse}
                    emailAddress={props.row.original.emailAddress}                          
                />
            }
        },
    ];
    
    return (
        <>
            <Table<IUserData> extra={extra} filters={filters} searchPlaceholder="search username, First/Last Name" columns={columns} data={users} loading={loading} total={total} hook={hook} />
        </>
    );
};
