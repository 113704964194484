import { ICompanyData } from "./types";
import { Button, Image } from 'react-bootstrap';
import Table, { LinkColumn } from "../../components/Table/index";
import { ColumnDef } from "@tanstack/table-core";
import { titleConvert } from './../../helpers/api/utils';
import {DEV_URL,rpost} from 'utils/requests';
import Text from 'components/Text';
import FlagIcon from 'components/icons/FlagIcon';
import { ISubscribeOptions, SubscribeModal } from "./SubscribeModal";
import { useEffect, useRef, useState } from "react";
import ThreeDotMenu from "components/ThreeDotMenu";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import MergeCompanyComponent,{MergeCompanyProps} from "./MergeCompany";
import { useAuth } from "providers/CustomAuthProvider";
type CompaniesDataProps = {
    companies?: ICompanyData[];
    hiddenColumns?: string[];
    enableActions?: boolean;
    loading?: boolean;
    total?: number;
    hook?: any;
    pageNo?: number;
    filters?: any;
    extra?: any;
};
const imageerror = (e) => {
    e.target.style.display = "none";
}



export const CompaniesDataTable = ({
    companies,
    loading,
    total,
    hook,
    filters,
    hiddenColumns,
    enableActions = false,
    extra,
}: CompaniesDataProps) => {
    
    const { isSuperAdmin } = useAuth();
    const mergeCompanyRef = useRef<MergeCompanyProps>(); 
    const columns: ColumnDef<ICompanyData>[] = [
        {
            header: "Company Name",
            accessorKey: "legalName",
            enableSorting: true,
            cell: (props) => {
                if (props.row.original.emailDomain === null || props.row.original.emailDomain === '') {
                    return (<div className="d-flex">
                        <div className="d-flex">
                            <div className="image-container-logo">
                            </div>
                            {
                                props.row.original.isSubscribe ?<FlagIcon style={{margin:'7px 0',fontSize:'0.8rem'}} className='light-green me-3'/>:<FlagIcon style={{margin:'7px 0',fontSize:'0.8rem'}} className='light-gray me-3'/>
                            }
                            <LinkColumn className="link-color company-name" value={titleConvert(props.row.original.legalName.trim())} url={`/companies/${props.row.original.id}`} />
                        </div>
                        <button onClick={()=>{followCompany(props.row.original.id,props.row.original.isSubscribe,props.row.index)}} className={`follow-button btn`}>{props.row.original.isSubscribe?'Following':'Follow'}</button>
                    </div>);
                }
                return <div className="d-flex">
                    <div className="d-flex">
                        <div className="image-container-logo">
                            <Image alt="logo images" onError={imageerror} src={'https://logo.clearbit.com/' + props.row.original.emailDomain} />
                        </div>
                            {
                                props.row.original.isSubscribe ?<FlagIcon  style={{margin:'7px 0',fontSize:'0.8rem'}} className='light-green me-3'/>:<FlagIcon  style={{margin:'7px 0',fontSize:'0.8rem'}} className='light-gray me-3'/>
                            }
                        <LinkColumn className="link-color company-name" value={titleConvert(props.row.original.legalName.trim())} url={`/companies/${props.row.original.id}`} />
                    </div>
                    <button onClick={()=>{followCompany(props.row.original.id,props.row.original.isSubscribe,props.row.index)}} className={`follow-button btn`}>{props.row.original.isSubscribe?'Following':'Follow'}</button>
                </div>
            },
        },
        {
            header: "Domain",
            accessorKey: "emailDomain",
            enableSorting: true,
            cell: (props) => {
                return <Text variant="link" className="link-color" url={((props.row.original.emailDomain === null || props.row.original.emailDomain === undefined) ? '' : 'http://' + props.row.original.emailDomain)}>
                    {((props.row.original.emailDomain === null || props.row.original.emailDomain === undefined) ? '' : props.row.original.emailDomain) }
                </Text>
            },
        },
        {
            header: "Number of Contacts",
            accessorKey: "contactCount",
            enableSorting: true,
            cell: (props) => {
                return <span>{`${(props.row.original.contactCount === null || props.row.original.contactCount === undefined) ? '' : props.row.original.contactCount}`}</span>
            },
        },
        {
            header: "Number of Licenses",
            accessorKey: "licenseCount",
            enableSorting: true,
            cell: (props) => {
                return <span>{`${(props.row.original.licenseCount === null || props.row.original.licenseCount === undefined) ? '' : props.row.original.licenseCount}`}</span>
            },
        },
        {
            header: "License Type",
            accessorKey: "licenseType",
            enableSorting: false,
            cell: (props) => {
                return <span>{`${((props.row.original.licenseType === undefined || props.row.original.licenseType === null || props.row.original.licenseType === "") ? "N/A" : props.row.original.licenseType)}`}</span>
            },
        },
        {
            header: "Action",
            accessorKey: "isSubscribe",
            enableSorting: false,
            cell: (props) => {
                return (
                    <ThreeDotMenu className="text-center">
                    <DropdownItem onClick={(e) => {
                   followCompany(props.row.original.id,props.row.original.isSubscribe,props.row.index);
                }}><small>{props.row.original.isSubscribe?'Following':'Follow'}</small></DropdownItem>
                {isSuperAdmin && <DropdownItem onClick={(e)=>{if(mergeCompanyRef.current){mergeCompanyRef.current.onShowMergeCompany(props.row.original);}}}><small>Merge</small></DropdownItem>}
                    </ThreeDotMenu>)
            },
        },
    
    ];
    const [selectedValue,setSelectedValue]= useState<ISubscribeOptions>({
        email:false,
        push:false,
        sms:false,
        web:false
    });
    const followCompany = async (companyId,isSubscribed,index)=>{

        const response = await rpost(`${DEV_URL}/api/Company/SubscribeNotification`,{
            CompanyId:companyId,IsSubscribe:!isSubscribed
        });

        if(response !== "Data not found"){
            if(response.data.includes('Notifications')){
                companies[index].isSubscribe = !isSubscribed;
                extra.setCompanyData([...companies]);
            }  
        } 
    }
    const onSubmit = (reponseData)=>{
        
    }
    const [selectedCompnay,setSelectedCompnay] = useState("");
    const [isModalShow,setIsModalShow] = useState(false);
    const onModalPopup : Function = (companyId,isSubscribed,subscribeTypes)=>{
        setSelectedCompnay(companyId);
        setIsModalShow(true);
        const val:ISubscribeOptions = {
            email:false,
            web:false,
            sms:false,
            push:false,
        }
        setSelectedValue(val);
    }
    return (<>
        <MergeCompanyComponent ref={mergeCompanyRef}></MergeCompanyComponent>
        <div className="companyTable">
            <SubscribeModal value={selectedValue} onSubmit={onSubmit} companyId={selectedCompnay} show={isModalShow} setShow={setIsModalShow}/>
            <Table<ICompanyData> extra={extra} filters={filters} searchPlaceholder="search name, license or domain" columns={columns} data={companies} loading={loading} total={total} hook={hook}  />
        </div>
    </>
    );
};
